export enum Scope {
  MarketingEvents = 'opscentral_marketing_events',
  MarketingAds = 'opscentral_marketing_ads',
  MarketingCrossSell = 'opscentral_marketing_cross-sell',
  MarketingAffiliate = 'opscentral_marketing_affiliate',
  MarketingCrm = 'opscentral_marketing_crm',
  Storefronts = 'opscentral_storefronts',
  CommerceStores = 'opscentral_commerce_stores',
  CommerceDemandPlans = 'opscentral_commerce_demand-plans',
  CommerceSuppliers = 'opscentral_commerce_suppliers',
  CommerceOrders = 'opscentral_commerce_orders',
  CommerceReturns = 'opscentral_commerce_returns',
  CommerceSplitOrders = 'opscentral_commerce_split-orders',
  CommerceCatalog = 'opscentral_commerce_catalog',
  CommerceCustomerSupport = 'opscentral_commerce_customer-support',
  MerchantPricing = 'opscentral_merchant_pricing',
  MerchantMarketing = 'opscentral_merchant_marketing',
  PricingMerchantMarketing = 'opscentral_pricing_merchant_marketing',
  Gumdrop = 'opscentral_gumdrop',
}
