import { useState } from 'react'
import { Box, Flex, Text } from '@openstore/react-ui'
import React from 'react'

type Option = {
  label: string
  value: string
}

type SegmentedControlProps = {
  name?: string
  options: Option[]
  onChange?: (value: string) => void
} & ({ value: string; defaultValue?: never } | { defaultValue: string; value?: never })

function SegmentedControl({ name, options, onChange, value, defaultValue }: SegmentedControlProps) {
  const [internalValue, setInternalValue] = useState(defaultValue)

  const isUncontrolled = Boolean(defaultValue)
  const computedValue = isUncontrolled ? internalValue : value

  const onInputChange = React.useCallback(
    (value: string) => {
      if (onChange) {
        onChange(value)
      }

      if (isUncontrolled) {
        setInternalValue(value)
      }
    },
    [onChange, isUncontrolled]
  )

  return (
    <Flex
      display="inline-flex"
      justify="space-between"
      background="white"
      border="1px"
      borderColor="gray.300"
      overflow="hidden"
      borderRadius="md"
    >
      {options.map(({ value: currentValue, label }) => {
        const isActive = currentValue === computedValue
        return (
          <Box
            key={currentValue}
            position="relative"
            sx={{
              ['&:not(:last-child)']: {
                borderRight: '1px',
                borderColor: 'gray.300',
              },
            }}
          >
            <Box
              as="input"
              type="radio"
              /** Make invisible */
              opacity="0"
              top="0"
              right="0"
              bottom="0"
              left="0"
              position="absolute"
              cursor="pointer"
              value={currentValue}
              id={label}
              name={name}
              onChange={() => onInputChange(currentValue)}
              checked={isActive}
            />
            <Flex
              as="label"
              htmlFor={label}
              px="4"
              height="40px"
              alignItems="center"
              bg={isActive ? 'gray.100' : undefined}
            >
              <Text textStyle={isActive ? 'body1Semibold' : 'body1'}>{label}</Text>
            </Flex>
          </Box>
        )
      })}
    </Flex>
  )
}

export default SegmentedControl
