import { Box, Tab, TabList, Tabs as TabsBase } from '@openstore/react-ui'
import Badge, { BadgeProps } from '@openstore/react-ui/dist/components/badge/Badge'
import Link from 'next/link'
import React from 'react'

export type TabsProps = {
  index?: number
  defaultIndex?: number
  items: TabProps[]
}

export type TabProps = {
  name: string
  badge?: BadgeProps
} & (
  | { href: string; value?: never; onClick?: never }
  | { value: string; onClick: (value: string) => void; href?: never }
)

function TabContainer({ href, name, children, onClick, value }: React.PropsWithChildren<TabProps>) {
  if (href) {
    return (
      <Link key={name} href={href}>
        {children}
      </Link>
    )
  }

  return <Box onClick={() => onClick!(value!)}>{children}</Box>
}

function Tabs({ index, defaultIndex, items }: TabsProps) {
  return (
    <TabsBase mt="4" isLazy={true} index={index} defaultIndex={defaultIndex}>
      <TabList borderBottom="1px" borderColor="gray.300">
        {items.map((tab) => {
          return (
            <TabContainer key={tab.name} {...tab}>
              <Tab
                _selected={{ color: 'black', fontWeight: 'bold', borderBottom: '2px' }}
                _active={{
                  bg: 'inherit',
                }}
                color="gray.650"
                py="3"
                px="3"
                fontSize="sm"
                mr="2"
              >
                {tab.name}
                {tab.badge && (
                  <Box ml="2">
                    <Badge size="sm" {...tab.badge} />
                  </Box>
                )}
              </Tab>
            </TabContainer>
          )
        })}
      </TabList>
    </TabsBase>
  )
}

export default Tabs
