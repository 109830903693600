import { Flex, Text } from '@openstore/react-ui'

type Size = 'sm' | 'md'

function NumberBadge({
  children,
  size = 'md',
  ...rest
}: React.PropsWithChildren<React.ComponentProps<typeof Flex>> & {
  size?: Size
}) {
  const badgeSize = size === 'md' ? '30px' : '20px'
  const textStyle = size === 'md' ? 'body1' : 'body2'
  return (
    <Flex
      height={badgeSize}
      width={badgeSize}
      bg="gray.300"
      borderRadius={15}
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      {typeof children === 'string' || typeof children === 'number' ? (
        <Text textStyle={textStyle}>{children}</Text>
      ) : (
        children
      )}
    </Flex>
  )
}

export default NumberBadge
