import { Skeleton, Stack } from '@openstore/react-ui'

function SkeletonGroup() {
  return (
    <Stack spacing="3">
      <Skeleton height="18px" width="50%" />
      <Skeleton height="18px" width="100%" />
      <Skeleton height="18px" width="100%" />
      <Skeleton height="18px" width="100%" />
      <Skeleton height="18px" width="100%" />
    </Stack>
  )
}

export default SkeletonGroup
