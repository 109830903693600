import {
  IconArrowUpRight,
  MenuButton,
  MenuGroup,
  MenuItem as MenuItemRaw,
  MenuList,
  Menu as MenuRaw,
  Portal,
  Text,
} from '@openstore/react-ui'
import React from 'react'

type MenuItemProps = {
  name: React.ReactNode
  onClick?: () => void
  isExternal?: boolean
  isDisabled?: boolean
}

type GroupedItemProps = {
  group: string
  items: MenuItemProps[]
}

export function isGroupedItem(item: MenuItemProps | GroupedItemProps): item is GroupedItemProps {
  return 'group' in item
}

export type MenuProps = {
  isDisabled?: boolean
  zIndex?: number
  items: (MenuItemProps | GroupedItemProps)[]
}

function MenuItem({ onClick, name, isExternal, isDisabled }: MenuItemProps) {
  return (
    <MenuItemRaw closeOnSelect={Boolean(onClick)} pl="4" py="2" onClick={onClick} isDisabled={isDisabled}>
      {isExternal && <IconArrowUpRight color="gray.650" mr="2" />}
      {typeof name === 'string' ? <Text textStyle="body1">{name}</Text> : name}
    </MenuItemRaw>
  )
}

function Menu({ items, isDisabled, zIndex, children }: React.PropsWithChildren<MenuProps>) {
  return (
    <MenuRaw
      strategy="fixed"
      preventOverflow={true}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: ({ reference }: any) => {
              const offsetX = reference.x / 10 // ACCOUNT FOR 10% ZOOM
              const offsetY = reference.y / 10 // ACCOUNT FOR 10% ZOOM
              return [-offsetX, -offsetY]
            },
          },
        },
      ]}
    >
      <MenuButton
        type="button"
        disabled={isDisabled}
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        {children}
      </MenuButton>
      <Portal>
        <MenuList boxShadow="md" zIndex={zIndex}>
          {items.map((item, idx) => {
            if (isGroupedItem(item)) {
              return (
                <MenuGroup title={item.group} color="gray.650">
                  {item.items.map((innerItem, innerIdx) => {
                    return <MenuItem key={innerIdx} {...innerItem} />
                  })}
                </MenuGroup>
              )
            }
            return <MenuItem key={idx} isDisabled={item.isDisabled} {...item} />
          })}
        </MenuList>
      </Portal>
    </MenuRaw>
  )
}

export default Menu
