import React from 'react'
import { ModalControllerContext, ModalProps, ModalStateContext } from './ModalContext'

function ModalProvider({ children }: React.PropsWithChildren) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [props, setProps] = React.useState<ModalProps | undefined>(undefined)

  const controls = React.useMemo(
    () => ({
      showModal: (props: ModalProps | undefined) => {
        setProps(props)
        setIsOpen(true)
      },
      closeModal: () => {
        setIsOpen(false)
      },
      updateModal: (newProps: ModalProps | undefined) => {
        setProps((props) => ({
          ...props,
          ...newProps,
        }))
      },
    }),
    []
  )

  return (
    <ModalControllerContext.Provider value={controls}>
      <ModalStateContext.Provider
        value={{
          isOpen,
          props,
        }}
      >
        {children}
      </ModalStateContext.Provider>
    </ModalControllerContext.Provider>
  )
}

export default ModalProvider
