import { Container, Flex, Stack, Text } from '@openstore/react-ui'
import React, { PropsWithChildren } from 'react'
import PageEmpty, { PageEmptyProps } from './PageEmpty'
import PageError, { PageErrorProps } from './PageError'
import PageHeader, { PageHeaderProps } from './PageHeader'
import PageLoader from './PageLoader'

enum PageSizes {
  XXSMALL = '560', // for page with a simple form
  XSMALL = '720', // for page with a simple list
  SMALL = '800', // for smaller detail with forms within cards
  MEDIUM = '1200', // for most detail pages with tabs
  LARGE = '2200', // full list pages
}

type BoxProps = React.ComponentProps<typeof Flex>

type PageProps = {
  header?: PageHeaderProps
  centered?: boolean
  loading?: boolean
  empty?: PageEmptyProps
  error?: PageErrorProps['error']
  size?: PageSizes
  overflow?: BoxProps['overflow']
  background?: BoxProps['background']
  py?: BoxProps['py']
  pt?: BoxProps['pt']
  pb?: BoxProps['pb']
  px?: BoxProps['px']
}

export default function Page({
  children,
  header,
  centered,
  empty,
  loading = false,
  error,
  size = PageSizes.LARGE,
  overflow = 'auto',
  background,
  py = '8',
  px = '8',
  pt,
  pb,
}: React.PropsWithChildren<PageProps>) {
  const content = React.useMemo(() => {
    if (loading) {
      return <PageLoader />
    }

    if (error) {
      return <PageError error={error} />
    }

    if (children) {
      return children
    }

    if (empty) {
      return <PageEmpty {...empty} />
    }

    return null
  }, [children, loading, error, empty])

  const isOverflowHidden = overflow === 'hidden'
  return (
    <Flex height="100%" flex={1} flexDir="column" background={background} overflow="hidden">
      <Flex
        as="main"
        flexDir={'column'}
        flex={1}
        py={py}
        pt={pt}
        pb={pb}
        overflow={overflow}
        {...(centered
          ? {
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {})}
      >
        <Container
          maxW={`${size}px`}
          display="flex"
          flex={1}
          flexDir="column"
          px={px}
          height={isOverflowHidden ? '100%' : undefined}
        >
          {header && <PageHeader {...header} />}
          {content}
        </Container>
      </Flex>
    </Flex>
  )
}

Page.Header = PageHeader
Page.Loader = PageLoader
Page.Error = PageError
Page.Empty = PageEmpty
Page.Sizes = PageSizes

Page.Section = function PageSection({
  children,
  title,
}: PropsWithChildren<{
  title: string
}>) {
  return (
    <Stack spacing="4">
      <Text
        flex={1}
        textAlign="left"
        textStyle="body1Semibold"
        sx={{ textTransform: 'uppercase', letterSpacing: '0.08em' }}
        color="gray.650"
      >
        {title}
      </Text>
      {children}
    </Stack>
  )
}
