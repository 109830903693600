import { Scope } from 'modules/authorization/server/types'
type Arg = number | string

export type Route = Record<
  string,
  {
    route: (...args: Arg[]) => string
    scopes: Scope[]
  }
>

const ROUTES = {
  BASE: { route: () => '/', scopes: [] },

  /**
   * Marketing
   */
  MARKETING: { route: () => '/marketing', scopes: [] },
  MARKETING_PROMOTIONS: { route: () => '/marketing/promotions', scopes: [Scope.MarketingEvents] },
  MARKETING_PROMOTION: {
    route: (id: Arg) => `/marketing/promotions/${id}`,
    scopes: [Scope.MarketingEvents],
  },
  MARKETING_PROMOTION_HERO_BANNER: {
    route: (id: Arg) => `/marketing/promotions/${id}/hero_banner`,
    scopes: [Scope.MarketingEvents],
  },
  MARKETING_AUTOMATED_ADS: { route: () => '/marketing/automated_ads', scopes: [Scope.MarketingAds] },
  MARKETING_AUTOMATED_AD: {
    route: (id: Arg) => `/marketing/automated_ads/${id}`,
    scopes: [Scope.MarketingAds],
  },
  MARKETING_CROSS_SELLING_CAMPAIGNS: {
    route: () => '/marketing/cross_selling/cross_sell_campaigns',
    scopes: [Scope.MarketingCrossSell],
  },
  MARKETING_CROSS_SELLING_CAMPAIGN: {
    route: (id: Arg) => `/marketing/cross_selling/cross_sell_campaigns/${id}`,
    scopes: [Scope.MarketingCrossSell],
  },
  MARKETING_AFFILIATE_NEWSLETTER: {
    route: () => '/marketing/affiliate/newsletter',
    scopes: [Scope.MarketingAffiliate],
  },
  MARKETING_CRM_BROADCASTS: {
    route: () => '/marketing/crm/broadcasts',
    scopes: [Scope.MarketingCrm],
  },
  MARKETING_CRM_EMAIL_BROADCAST: {
    route: (id: Arg) => `/marketing/crm/broadcasts/${id}/email`,
    scopes: [Scope.MarketingCrm],
  },
  MARKETING_CRM_FLOW_BROADCAST: {
    route: (id: Arg) => `/marketing/crm/broadcasts/${id}/flow`,
    scopes: [Scope.MarketingCrm],
  },
  MARKETING_CRM_BRAND_SETTINGS: {
    route: () => '/marketing/crm/brand_settings',
    scopes: [Scope.MarketingCrm],
  },
  MARKETING_CAMPAIGNS: {
    route: () => '/marketing/campaigns',
    scopes: [Scope.MarketingCrm],
  },
  MARKETING_CRM_BRAND_SETTING: {
    route: (id: Arg) => `/marketing/crm/brand_settings/${id}`,
    scopes: [Scope.MarketingCrm],
  },

  /**
   * Storefronts
   */
  STOREFRONTS: { route: () => '/storefronts', scopes: [Scope.Storefronts] },
  STOREFRONTS_STORES: { route: () => '/storefronts/stores', scopes: [Scope.Storefronts] },
  STOREFRONTS_STORE: {
    route: (id: Arg) => `/storefronts/stores/${id}`,
    scopes: [Scope.Storefronts],
  },
  STOREFRONTS_STORE_TAB: {
    route: (id: Arg, tabId: Arg) => `/storefronts/stores/${id}/${tabId}`,
    scopes: [Scope.Storefronts],
  },
  STOREFRONTS_STORE_HERO_BANNER_SETUP: {
    route: (id: Arg) => `/storefronts/stores/${id}/dynamic_hero_banner_settings/setup`,
    scopes: [Scope.Storefronts],
  },

  /**
   * Commerce
   */
  COMMERCE: { route: () => '/commerce', scopes: [] },
  COMMERCE_STORES: { route: () => '/commerce/stores', scopes: [Scope.CommerceStores] },
  COMMERCE_STORE: {
    route: (id: Arg) => `/commerce/stores/${id}`,
    scopes: [Scope.CommerceStores],
  },
  COMMERCE_STORE_TAB: {
    route: (id: Arg, tabId: Arg) => `/commerce/stores/${id}/${tabId}`,
    scopes: [Scope.CommerceStores],
  },
  COMMERCE_DEMAND_PLANS: {
    route: () => '/commerce/demand_plans',
    scopes: [Scope.CommerceDemandPlans],
  },
  COMMERCE_DEMAND_PLAN_CREATE: {
    route: () => '/commerce/demand_plans/create',
    scopes: [Scope.CommerceDemandPlans],
  },
  COMMERCE_DEMAND_PLANS_TAB: {
    route: (tabId: Arg) => `/commerce/demand_plans/${tabId}`,
    scopes: [Scope.CommerceDemandPlans],
  },
  COMMERCE_SUPPLIERS: { route: () => '/commerce/suppliers', scopes: [Scope.CommerceSuppliers] },
  COMMERCE_SUPPLIER: {
    route: (id: Arg) => `/commerce/suppliers/${id}`,
    scopes: [Scope.CommerceSuppliers],
  },
  COMMERCE_SUPPLIER_EDIT: {
    route: (id: Arg) => `/commerce/suppliers/${id}/edit`,
    scopes: [Scope.CommerceSuppliers],
  },
  COMMERCE_ORDERS: { route: () => '/commerce/orders', scopes: [Scope.CommerceOrders] },
  COMMERCE_ORDER: {
    route: (id: Arg) => `/commerce/orders/${id}`,
    scopes: [Scope.CommerceOrders],
  },
  COMMERCE_RETURNS: { route: () => '/commerce/returns', scopes: [Scope.CommerceReturns] },
  COMMERCE_RETURN: {
    route: (id: Arg) => `/commerce/returns/${id}`,
    scopes: [Scope.CommerceReturns],
  },
  COMMERCE_PURCHASE_ORDERS: {
    route: () => '/commerce/purchase_orders',
    scopes: [Scope.CommerceSplitOrders],
  },
  COMMERCE_PURCHASE_ORDER: {
    route: (id: Arg) => `/commerce/purchase_orders/${id}`,
    scopes: [Scope.CommerceSplitOrders],
  },
  COMMERCE_CATALOG_BUNDLES: {
    route: () => '/commerce/catalog/bundles',
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_BUNDLE: {
    route: (id: Arg) => `/commerce/catalog/bundles/${id}`,
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_SALES_CHANNEL_BUNDLE: {
    route: (id: Arg, id2: Arg) => `/commerce/catalog/bundles/${id}/sales_channels/${id2}`,
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_PRODUCTS: {
    route: () => '/commerce/catalog/products',
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_PRODUCT: {
    route: (id: Arg) => `/commerce/catalog/products/${id}`,
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_PRODUCT_CREATE: {
    route: () => '/commerce/catalog/products/create',
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_SALES_CHANNEL_PRODUCT: {
    route: (id: Arg, id2: Arg) => `/commerce/catalog/products/${id}/sales_channels/${id2}`,
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_PRODUCT_OPTIONS: {
    route: () => '/commerce/catalog/product_options',
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_VARIANT: {
    route: (id: Arg) => `/commerce/catalog/variants/${id}`,
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_SALES_CHANNEL_VARIANT: {
    route: (id: Arg, id2: Arg) => `/commerce/catalog/variants/${id}/sales_channels/${id2}`,
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_VARIANTS_INCOMPLETE: {
    route: () => '/commerce/catalog/variants/incomplete',
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_SALES_CHANNELS: {
    route: () => '/commerce/catalog/sales_channels',
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_SALES_CHANNEL: {
    route: (id: Arg) => `/commerce/catalog/sales_channels/${id}`,
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_JOBS: {
    route: () => '/commerce/catalog/jobs',
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CATALOG_JOB: {
    route: (id: Arg) => `/commerce/catalog/jobs/${id}`,
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_BUNDLES: { route: () => '/commerce/bundles', scopes: [Scope.CommerceCatalog] },
  COMMERCE_BUNDLE_NEW: { route: () => '/commerce/bundles/new', scopes: [Scope.CommerceCatalog] },
  COMMERCE_BUNDLE_BULK_UPLOAD: {
    route: () => '/commerce/bundles/bulk_upload',
    scopes: [Scope.CommerceCatalog],
  },
  COMMERCE_CUSTOMER_SUPPORT: {
    route: () => '/commerce/customer_support',
    scopes: [Scope.CommerceCustomerSupport],
  },
  COMMERCE_CUSTOMER_SUPPORT_CLAIMS: {
    route: () => '/commerce/customer_support/claims',
    scopes: [Scope.CommerceCustomerSupport],
  },
  COMMERCE_CUSTOMER_SUPPORT_CLAIM: {
    route: (id: Arg) => `/commerce/customer_support/claims/${id}`,
    scopes: [Scope.CommerceCustomerSupport],
  },
  COMMERCE_CUSTOMER_SUPPORT_PROTECTION_PLANS: {
    route: () => '/commerce/customer_support/protection_plans',
    scopes: [Scope.CommerceCustomerSupport],
  },

  /**
   * Merchant
   */
  MERCHANT: { route: () => '/merchant', scopes: [] },
  MERCHANT_OFFER_MODIFICATION: {
    route: () => '/merchant/offer_modification',
    scopes: [Scope.MerchantPricing],
  },
  MERCHANT_STORE: { route: () => '/merchant/store', scopes: [Scope.MerchantPricing] },
  MERCHANT_OFFER_CREATION: { route: () => '/merchant/offer_creation', scopes: [Scope.MerchantPricing] },
  MERCHANT_CAMPAIGN_MANAGEMENT: {
    route: () => '/merchant/campaign_management',
    scopes: [Scope.MerchantMarketing],
  },
  MERCHANT_PRICING_FORECAST: {
    route: () => '/merchant/pricing_forecast',
    scopes: [Scope.MerchantPricing],
  },
  MERCHANT_PRICING_CAMPAIGN_MANAGEMENT: {
    route: () => '/merchant/pricing_campaign_management',
    scopes: [Scope.PricingMerchantMarketing],
  },
  MERCHANT_PRICING_CAMPAIGN: {
    route: (id: Arg) => `/merchant/pricing_campaign_management/${id}`,
    scopes: [Scope.PricingMerchantMarketing],
  },
  MERCHANT_LEAD: { route: () => '/merchant/lead', scopes: [Scope.MerchantPricing] },
  MERCHANT_ATTRIBUTION_CHANNELS: {
    route: () => '/merchant/attribution_channels',
    scopes: [Scope.MerchantMarketing],
  },

  /**
   * Gumdrop
   */
  GUMDROP: { route: () => '/gumdrop', scopes: [Scope.Gumdrop] },
  GUMDROP_ALERTS: { route: () => '/gumdrop/alerts', scopes: [Scope.Gumdrop] },
  GUMDROP_COLLAB_POSTS: { route: () => '/gumdrop/collab_posts', scopes: [Scope.Gumdrop] },
  GUMDROP_COLLAB_POSTS_TAB: {
    route: (id: Arg) => `/gumdrop/collab_posts/${id}`,
    scopes: [Scope.Gumdrop],
  },
  GUMDROP_COLLAB_POST_ATTRIBUTION_TASKS: {
    route: () => '/gumdrop/collab_post_attribution_tasks',
    scopes: [Scope.Gumdrop],
  },
  GUMDROP_COLLABS: { route: () => '/gumdrop/collabs', scopes: [Scope.Gumdrop] },
  GUMDROP_COLLAB: {
    route: (id: Arg) => `/gumdrop/collabs/${id}`,
    scopes: [Scope.Gumdrop],
  },
  GUMDROP_CREATORS: { route: () => '/gumdrop/creators', scopes: [Scope.Gumdrop] },
  GUMDROP_CREATOR: {
    route: (id: Arg) => `/gumdrop/creators/${id}`,
    scopes: [Scope.Gumdrop],
  },
  GUMDROP_DROPS: { route: () => '/gumdrop/drops', scopes: [Scope.Gumdrop] },
  GUMDROP_DROP: {
    route: (id: Arg) => `/gumdrop/drops/${id}`,
    scopes: [Scope.Gumdrop],
  },
  GUMDROP_SOCIAL_ACCOUNTS: { route: () => '/gumdrop/social_accounts', scopes: [Scope.Gumdrop] },
  GUMDROP_SOCIAL_ACCOUNTS_TAB: {
    route: (id: Arg) => `/gumdrop/social_accounts/${id}`,
    scopes: [Scope.Gumdrop],
  },
  GUMDROP_SOCIAL_POST_STATS: { route: () => '/gumdrop/social_post_stats', scopes: [Scope.Gumdrop] },
  GUMDROP_STOREFRONT_CONFIGS: { route: () => '/gumdrop/storefront_configs', scopes: [Scope.Gumdrop] },

  /**
   * SEO
   */
  SEO: { route: () => '/seo', scopes: [] },
  SEO_KEYWORD_RESULTS: { route: () => '/seo/keyword_results', scopes: [] },
  SEO_PROMPT_TEMPLATES: { route: () => '/seo/prompt_templates', scopes: [] },
  SEO_PROMPT_TEMPLATE_CREATE: {
    route: () => '/seo/prompt_templates/create',
    scopes: [],
  },
  SEO_PROMPT_TEMPLATE: {
    route: (id: Arg) => `/seo/prompt_templates/${id}`,
    scopes: [],
  },
}

export default ROUTES
