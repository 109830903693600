import React from 'react'
import { StaticDateRangePicker, LocalizationProvider, DateRange } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import { theme } from '@openstore/react-ui'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_KEY!)

const MUITheme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial',
  },
  palette: {
    primary: {
      main: theme.colors.primary[800],
      light: theme.colors.gray[200],
    },
  },
  /** In case we need */
  components: {
    MuiPickersArrowSwitcher: {},
    MuiPickersCalendarHeader: {},
    MuiPickersDay: {},
    MuiPickerStaticWrapper: {},

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
})

export type DateRangePickerProps = {
  onChange: (dates: DateRange<Date>) => void
} & ({ value: DateRange<Date>; defaultValue?: never } | { defaultValue: DateRange<Date>; value?: never })

export default function DateRangePicker({ defaultValue, value, onChange }: DateRangePickerProps) {
  const [internalValue, setInternalValue] = React.useState<DateRange<Date>>(defaultValue ?? [null, null])
  const computedValue: DateRange<Date> = value ?? internalValue

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIThemeProvider theme={MUITheme}>
        <StaticDateRangePicker
          calendars={1}
          displayStaticWrapperAs="desktop"
          value={computedValue}
          onChange={(newValue) => {
            if (defaultValue) {
              setInternalValue(newValue)
            }
            onChange(newValue)
          }}
          renderInput={() => <></>}
        />
      </MUIThemeProvider>
    </LocalizationProvider>
  )
}
