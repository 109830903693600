import {
  Box,
  Flex,
  HStack,
  IconCheckCircle,
  IconInfoCircle,
  IconLightbulb_01,
  IconXClose,
  RenderProps,
  Spinner,
  Text,
  ToastId,
  useToast as useChakraToast,
  UseToastOptions,
} from '@openstore/react-ui'
import capitalize from 'lodash/capitalize'
import React from 'react'
import { NAV_COLOR } from 'utils/style'

type ToastDefaults = {
  icon: React.ReactNode
}

function getToastDefaults(status: UseToastOptions['status']): ToastDefaults {
  switch (status) {
    case 'error': {
      return {
        icon: (
          <HStack p="1" bg="error.100" borderRadius="24">
            <HStack p="1" bg="error.200" borderRadius="12">
              <IconInfoCircle color="error.600" />
            </HStack>
          </HStack>
        ),
      }
    }
    case 'info': {
      return {
        icon: (
          <HStack p="1" bg="primary.200" borderRadius="24">
            <HStack p="1" bg="primary.400" borderRadius="12">
              <IconLightbulb_01 color="purple.600" />
            </HStack>
          </HStack>
        ),
      }
    }
    case 'warning':
      return {
        icon: (
          <HStack p="1" bg="warning.100" borderRadius="24">
            <HStack p="1" bg="warning.200" borderRadius="12">
              <IconInfoCircle color="warning.600" />
            </HStack>
          </HStack>
        ),
      }
    case 'loading':
      return {
        icon: <Spinner size="sm" label="" showLabel={false} alignItems="center" />,
      }
    case 'success':
      return {
        icon: (
          <HStack p="1" bg="success.100" borderRadius="24">
            <HStack p="1" bg="success.200" borderRadius="12">
              <IconCheckCircle color="success.600" />
            </HStack>
          </HStack>
        ),
      }
    default: {
      return {
        icon: null,
      }
    }
  }
}

function ToastContent({ status, title, description, isClosable, onClose }: RenderProps & UseToastOptions) {
  const defaults = getToastDefaults(status)
  return (
    <Box
      bg={NAV_COLOR}
      p="4"
      shadow="0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)"
      borderRadius="md"
      width="400px"
      maxHeight="500px"
      overflow="auto"
    >
      <HStack alignItems={description ? 'start' : 'center'} spacing="4">
        {defaults.icon && <Flex>{defaults.icon}</Flex>}
        <Flex flex={1} overflow="hidden" flexDir="column" justifyContent="center">
          {title &&
            (typeof title === 'string' ? (
              <Text color="white" textStyle="body1Semibold">
                {title}
              </Text>
            ) : (
              title
            ))}
          {description &&
            (typeof description === 'string' ? (
              <Text color="gray.500" textStyle="body1">
                {capitalize(description)}
              </Text>
            ) : (
              description
            ))}
        </Flex>
        {isClosable && <IconXClose onClick={onClose} color="white" width="18px" height="18px" cursor="pointer" />}
      </HStack>
    </Box>
  )
}

function getToastProps(options: UseToastOptions, defaultOptions?: UseToastOptions): UseToastOptions {
  /** Preset defaults */
  const isClosable = options.isClosable ?? true
  const duration =
    options.duration ?? (options.status === 'error' || options.status === 'warning' || options.status === 'loading')
      ? null
      : 4000

  return {
    duration,
    isClosable,
    ...options,
    render: (renderProps) => <ToastContent {...defaultOptions} {...options} {...renderProps} isClosable={isClosable} />,
  }
}

function useToast(defaultOptions?: UseToastOptions) {
  const toast = useChakraToast({
    position: 'bottom-right',
    ...defaultOptions,
  })

  return React.useMemo(() => {
    function customToast(options: UseToastOptions) {
      return toast(getToastProps(options, defaultOptions))
    }
    customToast.promise = toast.promise
    customToast.close = toast.close
    customToast.closeAll = toast.closeAll
    customToast.update = (id: ToastId, options: Omit<UseToastOptions, 'id'>) =>
      toast.update(id, getToastProps(options, defaultOptions))
    customToast.isActive = toast.isActive

    return customToast
  }, [toast, defaultOptions])
}

export default useToast
