export function getClientImageId(name: string, size: number, type: string) {
  // return `${name}-${size}-${type}`
  return `${name}-${type}`
}

export function getFileSrc(file?: FileContent) {
  if (!file) return ''
  if (isClientFile(file)) return file.fileData
  return file.fileUrl
}

export function getFileProperties(file: File): Promise<ClientFileContent> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      return resolve({
        fileData: reader.result ? reader.result.toString() : '',
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        isDirty: true,
        file,
        id: getClientImageId(file.name, file.size, file.type),
      })
    }

    reader.onerror = (error) => reject(error)
  })
}

export function cleanBase64Image(base64: string) {
  return base64.replace(/^data:image\/[a-z]+;base64,/, '')
}

export enum SupportedType {
  Image = 'image',
  ImageHeroBanner = 'imageHeroBanner',
  CSV = 'csv',
}

export function isClientFile(file: ClientFileContent | ServerFileContent): file is ClientFileContent {
  return 'fileData' in file
}

export function isServerFile(file: ClientFileContent | ServerFileContent): file is ServerFileContent {
  return 'fileUrl' in file
}

export type ClientFileContent = {
  /** Base64 */
  fileData: string

  /** File properties */
  fileName: string
  fileType: string
  fileSize: number

  /** Whether it was newly created / updated */
  isDirty: boolean
  file: File

  height?: number
  width?: number
  aspectRatio?: number
  id: string
}

export type ServerFileContent = {
  fileId: string

  /** URL */
  fileUrl: string

  /** File properties */
  fileName?: string
  fileType?: string
  fileSize?: number
}

export type FileContent = ClientFileContent | ServerFileContent
