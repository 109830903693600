import React from 'react'
import { Box } from '@openstore/react-ui'

type BoxProps = React.ComponentProps<typeof Box>

type LoadingOverlayProps = {
  loading: boolean
  opacity?: BoxProps['opacity']
}

const getLoadingStyles = (opacity: BoxProps['opacity'] = 0.7) => ({
  opacity,
  pointerEvents: 'none',
})

function LoadingOverlay({
  loading,
  children,
  sx,
  opacity,
  ...rest
}: React.PropsWithChildren<LoadingOverlayProps> & BoxProps) {
  const allSx = React.useMemo(() => {
    return {
      ...(loading ? getLoadingStyles(opacity) : undefined),
      ...sx,
    }
  }, [loading, opacity, sx])

  return (
    <Box sx={allSx} {...rest}>
      {children}
    </Box>
  )
}

export default LoadingOverlay
