import { Box, HStack, IconXClose, PrimaryButton, SecondaryButton } from '@openstore/react-ui'
import { PrimaryButtonProps } from '@openstore/react-ui/dist/components/button/PrimaryButton'
import React from 'react'
import { NAV_COLOR } from 'utils/style'

const defaultPrimaryButtonProps = {
  children: 'Save',
  onClick: () => undefined,
}

const defaultSecondaryButtonProps = {
  children: 'Cancel',
}

type OverlayHeaderProps = {
  primaryButtonProps?: Partial<PrimaryButtonProps> | false
  secondaryButtonProps?: Partial<PrimaryButtonProps> | false
  loading?: boolean
  showCloseButton?: boolean
  darkMode?: boolean
  onClose: () => void
}

function OverlayHeader({
  onClose,
  primaryButtonProps = defaultPrimaryButtonProps,
  secondaryButtonProps = defaultSecondaryButtonProps,
  showCloseButton = true,
  loading,
  darkMode,
}: OverlayHeaderProps) {
  const showSecondaryButton = secondaryButtonProps !== false
  const showPrimaryButton = primaryButtonProps !== false
  return (
    <Box background={darkMode ? NAV_COLOR : 'white'} px="6" py="4" borderBottom="1px" borderColor="gray.200">
      <HStack flex={1} alignItems="center" minH="40px">
        {showCloseButton && (
          <HStack as="button" type="button" onClick={onClose} spacing="6">
            <IconXClose width="22px" height="22px" color={darkMode ? 'white' : undefined} />
          </HStack>
        )}

        <HStack spacing="3" flex={1} justify="end">
          {showSecondaryButton && (
            <SecondaryButton {...defaultSecondaryButtonProps} onClick={onClose} {...secondaryButtonProps} />
          )}
          {showPrimaryButton && (
            <PrimaryButton isLoading={loading} {...defaultPrimaryButtonProps} {...primaryButtonProps} />
          )}
        </HStack>
      </HStack>
    </Box>
  )
}

export default OverlayHeader
