import axios, { AxiosError } from 'axios'
import * as React from 'react'
import ErrorBlock from '../ErrorBlock'

export type PageErrorProps = {
  error: AxiosError | null | string
}

function PageError({ error }: PageErrorProps) {
  let message
  let description
  let stack
  if (axios.isAxiosError(error)) {
    message = error.code
    description = error.message
    stack = error.stack
  } else if (typeof error === 'string') {
    message = error
  }
  return <ErrorBlock title={message} description={description} stack={stack} />
}

export default PageError
