export const DEFAULT_SIZE = 25
export const DEFAULT_OFFSET = 0
export const DEFAULT_PAGE = 1
export const DEFAULT_NUMBER_OF_PAGES = 1

export const calculateOffset = (page: number, size: number): number => {
  return Math.max(page - 1, 0) * size
}
export const calculatePage = (total: number | undefined, offset: number, size: number): number => {
  try {
    const gatedOffset = total !== undefined ? Math.min(total, offset) : offset
    const numberOfPages = calculateNumberOfPages(total, size)
    return Math.min(Math.floor(gatedOffset / size) + 1, numberOfPages)
  } catch {
    return DEFAULT_PAGE
  }
}
export const calculateNumberOfPages = (total: number | undefined, size: number): number => {
  if (total === undefined) return DEFAULT_NUMBER_OF_PAGES
  try {
    return Math.ceil(total / size)
  } catch {
    return DEFAULT_NUMBER_OF_PAGES
  }
}
