import { Box, HStack, IconArrowLeft, Stack, Text, VStack } from '@openstore/react-ui'
import Link from 'next/link'
import ROUTES from 'routes'

const DEFAULTS = {
  TITLE: 'Oops. Something went wrong.',
  DESCRIPTION: 'Please try again. If the issue persists, contact engineering.',
}
type ErrorBlockProps = {
  title?: string
  description?: string
  stack?: string
}

function ErrorBlock({ title = DEFAULTS.TITLE, description = DEFAULTS.DESCRIPTION, stack }: ErrorBlockProps) {
  return (
    <VStack flex={1} mt="4%">
      <Stack spacing="4">
        <Stack spacing="4">
          <HStack textDecoration="underline" textDecorationColor="gray.650" spacing="2" alignItems="start">
            <IconArrowLeft color="gray.650" />

            <Text textStyle="body2" color="gray.650">
              <Link href={ROUTES.BASE.route()}>Go back home</Link>
            </Text>
          </HStack>
          <Text as="h2">{title}</Text>
        </Stack>
        <Stack spacing="4">
          <Text textStyle="body1">{description}</Text>
          {stack && (
            <Box
              border="1px"
              borderColor="gray.200"
              p="4"
              bg="gray.100"
              borderRadius="md"
              maxWidth={800}
              overflow="auto"
            >
              <Text textStyle="body2" color="gray.650">
                <code>{stack}</code>
              </Text>
            </Box>
          )}
        </Stack>
      </Stack>
    </VStack>
  )
}

export default ErrorBlock
