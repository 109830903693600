import { createContext } from 'react'
import { AlertDialogProps, Button } from '@openstore/react-ui'

type ButtonProps = React.ComponentProps<typeof Button>

export type AlertProps = {
  title?: React.ReactNode
  description?: React.ReactNode
  onConfirm?: (() => void) | (() => Promise<void>)
  async?: boolean
  primaryButtonProps?: Partial<ButtonProps> | false
  secondaryButtonProps?: Partial<ButtonProps> | false
  size?: AlertDialogProps['size']
  showFooter?: boolean
}

type AlertControllerProps = {
  showAlert: (alertProps?: AlertProps) => void
  closeAlert: () => void
}

type AlertStateProps = {
  isOpen: boolean
  props: AlertProps | undefined
}

export const AlertControllerContext = createContext<AlertControllerProps>({
  showAlert: () => undefined,
  closeAlert: () => undefined,
})
export const AlertStateContext = createContext<AlertStateProps>({
  isOpen: false,
  props: undefined,
})
