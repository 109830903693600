import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, IconChevronRight } from '@openstore/react-ui'
import Link from 'next/link'

export type BreadcrumbProps = {
  name: string
} & ({ href: string; current?: never } | { current: true; href?: never })

export default function Breadcrumbs({ items }: { items: BreadcrumbProps[] }) {
  if (items.length <= 2) {
    console.warn('Consider using back navigation instead of breadcrumbs.')
  }

  return (
    <Breadcrumb spacing="4px" separator={<IconChevronRight color="gray.500" />} mb="4" fontSize="sm">
      {items.map((item) => {
        const current = Boolean(item.current)
        return (
          <BreadcrumbItem
            key={item.name}
            color={current ? 'gray.650' : 'inherit'}
            pointerEvents={current ? 'none' : 'inherit'}
            textDecoration={current ? 'none' : 'underline'}
          >
            <BreadcrumbLink as={Link} href={item.href ?? ''} isCurrentPage={current}>
              {item.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
}
