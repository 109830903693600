import React from 'react'
import { StaticDatePicker, LocalizationProvider, StaticDatePickerProps } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'
import { theme } from '@openstore/react-ui'
import { LicenseInfo } from '@mui/x-license-pro'

LicenseInfo.setLicenseKey(process.env.NEXT_PUBLIC_MUI_KEY!)

const MUITheme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial',
  },
  palette: {
    primary: {
      main: theme.colors.primary[800],
      light: theme.colors.gray[200],
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
})

export type DatePickerProps<TInputDate, TDate> = {
  onChange: (value: StaticDatePickerProps<TInputDate, TDate>['value']) => void
} & (
  | { value: StaticDatePickerProps<TInputDate, TDate>['value']; defaultValue?: never }
  | { defaultValue: StaticDatePickerProps<TInputDate, TDate>['value']; value?: never }
)

export default function DatePicker<TInputDate, TDate>({
  defaultValue,
  value,
  onChange,
}: DatePickerProps<TInputDate, TDate>) {
  const [internalValue, setInternalValue] = React.useState<StaticDatePickerProps<TInputDate, TDate>['value']>(
    defaultValue ?? null
  )
  const computedValue: StaticDatePickerProps<TInputDate, TDate>['value'] = value ?? internalValue

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MUIThemeProvider theme={MUITheme}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          value={computedValue}
          openTo="day"
          views={['day']}
          onChange={(newValue) => {
            if (defaultValue) {
              setInternalValue(newValue)
            }
            onChange(newValue)
          }}
          renderInput={() => <></>}
        />
      </MUIThemeProvider>
    </LocalizationProvider>
  )
}
