import { Box, Button, DrawerProps as ChakraDrawerProps } from '@openstore/react-ui'
import React, { createContext } from 'react'

type ButtonProps = React.ComponentProps<typeof Button>

export type DrawerProps = {
  title?: string | React.ReactNode
  primaryButtonProps?: Partial<ButtonProps> | false
  secondaryButtonProps?: Partial<ButtonProps> | false
  showFooter?: boolean
  showHeader?: boolean
  showCloseButton?: boolean
  pt?: React.ComponentProps<typeof Box>['pt']
  px?: React.ComponentProps<typeof Box>['px']
  async?: boolean
  size?: ChakraDrawerProps['size']
  closeOnOverlayClick?: boolean
  withDivider?: boolean
  children?: React.ReactNode | ((renderProps: DrawerRenderProps) => JSX.Element)
}

export type DrawerRenderProps = {
  closeDrawer: DrawerControllerProps['closeDrawer']
}

export type DrawerControllerProps = {
  showDrawer: (DrawerProps?: DrawerProps) => void
  updateDrawer: (DrawerProps?: DrawerProps) => void
  closeDrawer: () => void
}

type DrawerStateProps = {
  isOpen: boolean
  props: DrawerProps | undefined
}

export const DrawerControllerContext = createContext<DrawerControllerProps>({
  showDrawer: () => undefined,
  updateDrawer: () => undefined,
  closeDrawer: () => undefined,
})

export const DrawerStateContext = createContext<DrawerStateProps>({
  isOpen: false,
  props: undefined,
})
