import React from 'react'
import { AlertControllerContext, AlertStateContext, AlertProps } from './AlertContext'

function AlertProvider({ children }: React.PropsWithChildren) {
  const [props, setProps] = React.useState<AlertProps | undefined>(undefined)
  const isOpen = React.useMemo(() => Boolean(props), [props])

  const controls = React.useMemo(
    () => ({
      showAlert: (props: AlertProps | undefined) => setProps(props),
      closeAlert: () => setProps(undefined),
    }),
    []
  )

  return (
    <AlertControllerContext.Provider value={controls}>
      <AlertStateContext.Provider
        value={{
          isOpen,
          props,
        }}
      >
        {children}
      </AlertStateContext.Provider>
    </AlertControllerContext.Provider>
  )
}

export default AlertProvider
