import { formatDate } from 'utils/date'

export type AuditBlocksProps = {
  createdAt?: Date | string
  createdBy?: string
  updatedAt?: Date | string
  updatedBy?: string
  showBoth?: boolean
}

export function getAuditBlocks({ createdAt, updatedAt, createdBy, updatedBy, showBoth }: AuditBlocksProps): string[][] {
  const blocks = []

  if (updatedAt && updatedBy) {
    blocks.push(['Modified', formatDate(updatedAt, formatDate.FORMATS.DATETIME_TEXT), 'by', updatedBy])
    if (!showBoth) return blocks
  }

  if (createdAt && createdBy) {
    blocks.push(['Created', formatDate(createdAt, formatDate.FORMATS.DATETIME_TEXT), 'by', createdBy])
    if (!showBoth) return blocks
  }

  return blocks
}
