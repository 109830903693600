export enum Groups {
  HoratioMgmt = 'Horatio - Management',
  HoratioAll = 'Horatio',
  GrowthAssistantOpsSCM = 'Growth Assistant - Ops SCM',
  Employees = 'Employees',
  StorefrontsAssistant = 'Storefronts Assistant',
  HelpwareAll = 'Helpware All',
  ContractorsFullTime = 'Contractors - Full Time',
}

function hasOktaGroup(groups: Groups[]) {
  return (oktaGroups: string[] = []) => {
    const formattedGroups = groups.map((group) => group.toString())
    return oktaGroups.some((oktaGroup) => formattedGroups.includes(oktaGroup))
  }
}

/** Groups */
export const isEmployees = hasOktaGroup([Groups.Employees])
export const isContractorsFullTime = hasOktaGroup([Groups.ContractorsFullTime])
export const isGrowthAssistantOpsSCM = hasOktaGroup([Groups.GrowthAssistantOpsSCM])
export const isHoratio = hasOktaGroup([Groups.HoratioMgmt, Groups.HoratioAll])
export const isHelpware = hasOktaGroup([Groups.HelpwareAll])
export const isStorefrontsAssistant = hasOktaGroup([Groups.StorefrontsAssistant])

export function hasSignInAccess(groups: string[] = []) {
  return (
    isContractorsFullTime(groups) ||
    isEmployees(groups) ||
    isGrowthAssistantOpsSCM(groups) ||
    isHoratio(groups) ||
    isHelpware(groups) ||
    isStorefrontsAssistant(groups)
  )
}

/** TODO: Improve this temp feature permissioning */
export const isExternalUser = (groups: string[] = []) => isHoratio(groups) || isHelpware(groups)
export const isOMCUser = (groups: string[] = []) => isExternalUser(groups)
export type RouteWhitelist = {
  pages: string[]
  apis: string[]
}

export const horatioRoutes: RouteWhitelist = {
  pages: ['^/commerce/orders(/.*)?', '^/commerce/returns(/.*)?'],
  /** apis check not used currently */
  apis: [
    '^/api/bff/fulfillment/(get_orders|get_fulfillment|create_fulfillment|update_shipping_address|set_order_on_hold|get_fulfillment_by_order_name)$',
    '^/api/bff/brand/storefront/get_storefronts$',
    '^/api/bff/catalog/sku/details$',
  ],
}

export function hasRouteAccess(pathname: string, routeWhitelist: RouteWhitelist) {
  const routes = pathname.startsWith('/api') ? routeWhitelist.apis : routeWhitelist.pages
  const hasAccess = routes.some((route) => {
    const regex = new RegExp(route)
    return pathname.match(regex)
  })
  return hasAccess
}
