import { Box, Text, Tooltip as TooltipRaw } from '@openstore/react-ui'
import React from 'react'

type RawTooltipProps = React.ComponentProps<typeof TooltipRaw>
type TooltipProps = {
  text: React.ReactNode
  placement?: RawTooltipProps['placement']
  shouldWrapChildren?: boolean
  display?: RawTooltipProps['display']
  openDelay?: RawTooltipProps['openDelay']
}

function Tooltip({
  children,
  text,
  placement = 'bottom',
  shouldWrapChildren = true,
  display,
  openDelay,
}: React.PropsWithChildren<TooltipProps>) {
  const Container = shouldWrapChildren ? Box : React.Fragment
  return (
    <TooltipRaw
      openDelay={openDelay}
      tabIndex={-1}
      label={text ? <Text color="white">{text}</Text> : undefined}
      placement={placement}
      display={display}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: ({ reference }: any) => {
              const offsetX = reference.x / 10 // ACCOUNT FOR 10% ZOOM
              const offsetY = reference.y / 10 // ACCOUNT FOR 10% ZOOM
              return [-offsetX, -offsetY]
            },
          },
        },
      ]}
    >
      <Container>{children}</Container>
    </TooltipRaw>
  )
}

export default Tooltip
