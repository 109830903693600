import { Box, Text } from '@openstore/react-ui'
import { TextProps } from '@openstore/react-ui/dist/components/text/Text'
import React from 'react'
import { AuditBlocksProps, getAuditBlocks } from './utils'

type AuditProps = AuditBlocksProps & {
  textStyle?: TextProps['textStyle']
  textAlign?: TextProps['textAlign']
  inline?: boolean
  showBoth?: boolean
}

function Audit({
  createdBy,
  createdAt,
  updatedAt,
  updatedBy,

  textStyle = 'body2',
  textAlign = 'left',

  showBoth = false,
  inline = false,
}: AuditProps) {
  const blocks = getAuditBlocks({ createdBy, createdAt, updatedAt, updatedBy, showBoth })

  if (!blocks.length) return null

  const renderBlock = (block: string[]) => {
    if (inline) {
      return (
        <>
          <Text textStyle={textStyle} color="gray.650" textAlign={textAlign}>
            {block[0]} <strong>{block[1]}</strong> {block[2]} <strong>{block[3]}</strong>
          </Text>
        </>
      )
    }

    return (
      <>
        <Text textStyle={textStyle} color="gray.650" textAlign={textAlign}>
          {block[0]} <strong>{block[1]}</strong>
        </Text>
        <Text textStyle={textStyle} color="gray.650" textAlign={textAlign}>
          {block[2]} <strong>{block[3]}</strong>
        </Text>
      </>
    )
  }

  return (
    <Box>
      {blocks.map((block, idx) => {
        return <React.Fragment key={idx}>{renderBlock(block)}</React.Fragment>
      })}
    </Box>
  )
}

export default Audit
