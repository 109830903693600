import {
  Badge,
  Box,
  Divider,
  Flex,
  HStack,
  IconArrowLeft,
  IconButton,
  IconDotsHorizontal,
  IconInfoCircle,
  Text,
} from '@openstore/react-ui'
import { BadgeProps } from '@openstore/react-ui/dist/components/badge/Badge'
import React from 'react'

import { ImageProps } from '@components/ui/Image/Image'
import Head from 'next/head'
import Link from 'next/link'
import Image from '../../Image'
import Breadcrumbs, { BreadcrumbProps } from './Breadcrumbs'
import Tabs, { TabProps } from './Tabs'
import { TextProps } from '@openstore/react-ui/dist/components/text/Text'
import { Menu, Tooltip } from '@components/ui'
import { MenuProps } from '@components/ui/Menu/Menu'

type BoxProps = React.ComponentProps<typeof Box>

export type PageHeaderProps = {
  title?: React.ReactNode
  metaTitle?: string
  description?: React.ReactNode
  children?: React.ReactNode
  tabs?: TabProps[]
  titleRight?: React.ReactNode
  right?: React.ReactNode
  left?: React.ReactNode
  badges?: BadgeProps[]
  withDivider?: boolean
  tooltip?: string
  pb?: BoxProps['pb']
} & (
  | { backHref: string; breadcrumbs?: never }
  | { breadcrumbs: BreadcrumbProps[]; backHref?: never }
  | { breadcrumbs?: never; backHref?: never }
)

function PageHeaderTitle({ children, ...rest }: React.PropsWithChildren<TextProps>) {
  return (
    <Text as="h2" {...rest}>
      {children}
    </Text>
  )
}

export default function PageHeader({
  title,
  metaTitle,
  description,
  backHref,
  breadcrumbs = [],
  children,
  tabs = [],
  badges = [],
  titleRight,
  left,
  right,
  tooltip = '',
  withDivider = false,
  pb = '6',
}: PageHeaderProps) {
  const hasTabs = tabs.length > 0
  const computedMetaTitle = metaTitle || (title && typeof title === 'string' ? title : '')
  return (
    <Flex flexDir="column" pb={pb}>
      {computedMetaTitle && (
        <Head>
          <title>{`${computedMetaTitle} - Ops Central`}</title>
        </Head>
      )}

      <Flex flexWrap="wrap" rowGap={4}>
        <Flex flexDir="column">
          {backHref && (
            <Link href={backHref}>
              <HStack mb="4" cursor="pointer">
                <IconArrowLeft width="22px" height="22px" />
              </HStack>
            </Link>
          )}
          {breadcrumbs.length > 0 && <Breadcrumbs items={breadcrumbs} />}

          <Flex flex={1} alignItems="flex-end">
            <HStack spacing="4" flex={1}>
              {left}
              <Flex flex={1} flexDir="column">
                <HStack flex={1} spacing="3">
                  {typeof title === 'string' ? <PageHeaderTitle>{title}</PageHeaderTitle> : title}

                  {tooltip && (
                    <Tooltip text={tooltip}>
                      <IconInfoCircle />
                    </Tooltip>
                  )}

                  {badges.length > 0 && (
                    <HStack flexShrink={0}>
                      {badges.map((badge, idx) => (
                        <Badge key={idx} {...badge} />
                      ))}
                    </HStack>
                  )}

                  {titleRight}
                </HStack>
                {description &&
                  (typeof description === 'string' ? (
                    <Text as="p" color="gray.650" marginTop="1">
                      {description}
                    </Text>
                  ) : (
                    description
                  ))}
              </Flex>
            </HStack>
          </Flex>
        </Flex>
        {right && (
          <Flex flex={1} flexDirection="column" ml="2" alignItems="end">
            {right}
          </Flex>
        )}
      </Flex>

      {children}

      {hasTabs && <Tabs items={tabs} />}

      {withDivider && <Divider borderColor="gray.300" mt="6" mb="2" />}
    </Flex>
  )
}

PageHeader.Title = PageHeaderTitle

PageHeader.Image = function PageHeaderImage({
  height = '64px',
  width = '64px',
  alt = 'page header image',
  ...rest
}: React.PropsWithChildren<ImageProps>) {
  return <Image height={height} width={width} alt={alt} background="white" objectFit="contain" {...rest} />
}

PageHeader.Menu = function PageHeaderMenu({ items }: { items: MenuProps['items'] }) {
  return (
    <Menu items={items}>
      <IconButton
        aria-label="Options"
        icon={<IconDotsHorizontal width="22px" height="22px" />}
        variant="outline"
        width="40px"
      />
    </Menu>
  )
}
