import { Box, IconAlertCircle, PrimaryButton, Text, VStack } from '@openstore/react-ui'
import { IconProps } from '@openstore/react-ui/dist/components/icon/Icon'
import Link from 'next/link'
import React, { PropsWithChildren } from 'react'

export type PageEmptyProps = {
  title: string
  description?: React.ReactNode
  cta?: {
    title: string
    href: string
  }
  icon?: (props: IconProps) => JSX.Element
}

function PageEmpty({ title, description, icon = IconAlertCircle, cta, children }: PropsWithChildren<PageEmptyProps>) {
  return (
    <VStack flex={1} justifyContent="center" height="100%">
      <VStack spacing="4" p="8" borderRadius="md" maxWidth={500}>
        {icon &&
          React.createElement(icon, {
            height: 30,
            width: 30,
          })}
        <Text as="h3" textAlign="center">
          {title}
        </Text>
        {description &&
          (typeof description === 'string' ? (
            <Text textStyle="body1" color="gray.650" textAlign="center">
              {description}
            </Text>
          ) : (
            description
          ))}
        {cta && (
          <Box>
            <PrimaryButton marginTop="2">
              <Link href={cta.href}>{cta.title}</Link>
            </PrimaryButton>
          </Box>
        )}
        {children}
      </VStack>
    </VStack>
  )
}

export default PageEmpty
