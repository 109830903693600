import { createContext, useContext } from 'react'

export type GroupSelectorContextProps = {
  value?: string
  onChange: (newValue: string) => void
  hasError?: boolean
}

const GroupSelectorContext = createContext<GroupSelectorContextProps>({
  value: undefined,
  onChange: () => undefined,
})

export default GroupSelectorContext

export function useGroupContext() {
  const context = useContext(GroupSelectorContext)
  if (!context) {
    throw new Error('This context must be inside a GroupSelector component.')
  }
  return context
}
