import {
  Box,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Modal as RawModal,
} from '@openstore/react-ui'
import React, { useState } from 'react'
import { ModalControllerContext, ModalProps, ModalStateContext } from './ModalContext'
import IconTitle from './components/IconTitle'
import OverlayHeader from './components/OverlayHeader'

export const defaultPrimaryButtonProps = {
  children: 'Save',
  onClick: () => undefined,
}

export const defaultSecondaryButtonProps = {
  children: 'Cancel',
}

export function Modal({
  title,
  primaryButtonProps = defaultPrimaryButtonProps,
  secondaryButtonProps = defaultSecondaryButtonProps,
  showFooter = true,
  showHeader = true,
  closeOnOverlayClick = true,
  async,
  background = 'white',
  px = '8',
  pt = '2',
  size = 'md',
  children,
  isOpen,
  closeModal,
  scrollBehavior = 'outside',
}: ModalProps & {
  isOpen: boolean
  closeModal: () => void
}) {
  const [loading, setLoading] = useState(false)
  const shouldPreventClose = !closeOnOverlayClick || (Boolean(async) && loading)

  const isOverlay = size === 'full'
  const hasFooter = showFooter && (primaryButtonProps || secondaryButtonProps)

  const handlePrimaryClick = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!primaryButtonProps || !primaryButtonProps.onClick) return

      if (async) {
        setLoading(true)
        primaryButtonProps.onClick && (await primaryButtonProps.onClick(event))
        setLoading(false)
        return
      }

      primaryButtonProps.onClick(event)
    },
    [primaryButtonProps, async]
  )

  return (
    <RawModal
      isOpen={isOpen}
      onClose={closeModal}
      closeOnOverlayClick={!shouldPreventClose}
      closeOnEsc={!shouldPreventClose}
      size={size}
      scrollBehavior={scrollBehavior}
      preserveScrollBarGap={true}
    >
      <ModalOverlay />
      <ModalContent margin="auto" background={background} overflow="hidden" data-size={size}>
        {showHeader && (
          <ModalHeader mt="4" position="relative" px={px} lineHeight="30px">
            <Box mr="5">{title}</Box>
            <ModalCloseButton top="14px" right="6" size={isOverlay ? 'lg' : undefined} />
          </ModalHeader>
        )}

        <ModalBody pb="0" pt={pt} px={px} display="flex" flexDir="column" background={background} flex={1}>
          {typeof children === 'function' ? children({ closeModal }) : children}
        </ModalBody>

        {hasFooter && (
          <Modal.Footer
            px={px}
            secondaryButtonProps={{
              onClick: closeModal,
              isDisabled: shouldPreventClose,
              ...defaultSecondaryButtonProps,
              ...secondaryButtonProps,
            }}
            primaryButtonProps={{
              isLoading: loading,
              ...defaultPrimaryButtonProps,
              ...primaryButtonProps,
              onClick: handlePrimaryClick,
            }}
          />
        )}
      </ModalContent>
    </RawModal>
  )
}

Modal.Footer = function CustomModalFooter({
  secondaryButtonProps = defaultSecondaryButtonProps,
  primaryButtonProps = defaultPrimaryButtonProps,
  px = 0,
}: {
  secondaryButtonProps?: ModalProps['secondaryButtonProps']
  primaryButtonProps?: ModalProps['primaryButtonProps']
  px?: ModalProps['px']
}) {
  return (
    <ModalFooter mt="6" pt="6" pb="6" px={px} borderTop="1px" borderColor="gray.200">
      {secondaryButtonProps && <Button variant="outline" {...defaultSecondaryButtonProps} {...secondaryButtonProps} />}
      {primaryButtonProps && <Button marginLeft={4} {...defaultPrimaryButtonProps} {...primaryButtonProps} />}
    </ModalFooter>
  )
}

Modal.IconTitle = IconTitle
Modal.OverlayHeader = OverlayHeader

function ModalManager() {
  const { isOpen, props } = React.useContext(ModalStateContext)
  const { closeModal } = React.useContext(ModalControllerContext)

  return <Modal isOpen={isOpen} closeModal={closeModal} {...props} />
}

export default ModalManager
