import { Box, HStack, IconInfoCircle, Stack, Text } from '@openstore/react-ui'
import React from 'react'
import Link from '../Link'
import Tooltip from '../Tooltip'

export type CardProps = {
  header?: {
    title: string
    description?: string
    right?: React.ReactNode
    tooltip?: string
  }
}
function Card({ header, children, ...rest }: React.PropsWithChildren<CardProps & React.ComponentProps<typeof Box>>) {
  return (
    <Box
      as="section"
      border="1px"
      borderColor="gray.300"
      background="white"
      borderRadius="md"
      boxShadow="sm"
      height="fit-content"
      overflow="hidden"
      sx={{
        ['article:not(:last-of-type)']: {
          borderBottom: '1px',
          borderColor: 'gray.200',
        },
      }}
      {...rest}
    >
      {header && (
        <HStack py="4" px="6" borderBottom="1px" borderColor="gray.300">
          <Box flex={1}>
            <Text textStyle="labelSemibold">{header.title}</Text>
            {header.description && (
              <Text textStyle="body2" color="gray.650">
                {header.description}
              </Text>
            )}
          </Box>

          {header.right}

          {header.tooltip && (
            <Tooltip text={header.tooltip}>
              <IconInfoCircle />
            </Tooltip>
          )}
        </HStack>
      )}
      {children}
    </Box>
  )
}

function CardSection({ children, ...rest }: React.PropsWithChildren<React.ComponentProps<typeof Box>>) {
  return (
    <Box as="article" py="6" px="6" {...rest}>
      {children}
    </Box>
  )
}

Card.Section = CardSection
Card.Label = function CardLabel({
  textStyle = 'body1',
  ...rest
}: React.PropsWithChildren<React.ComponentProps<typeof Text>>) {
  return <Text textStyle={textStyle} {...rest} />
}
Card.Text = function CardText({
  textStyle = 'body1',
  color = 'gray.650',
  ...rest
}: React.PropsWithChildren<React.ComponentProps<typeof Text>>) {
  return <Text textStyle={textStyle} color={color} {...rest} />
}
Card.Stack = function CardStack({
  spacing = '6',
  ...rest
}: React.PropsWithChildren<React.ComponentProps<typeof Stack>>) {
  return <Stack spacing={spacing} {...rest} />
}
Card.HStack = function CardHStack(props: React.PropsWithChildren<React.ComponentProps<typeof Stack>>) {
  return <HStack flexWrap="wrap" gap="6" spacing="0" alignItems="inherit" {...props} />
}
Card.EditButton = function CardEditButton({ onClick }: { onClick: () => void }) {
  return <Link onClick={onClick}>Edit</Link>
}
Card.ASIDE_WIDTH = 400

export default Card
