import { Box, HStack, Text } from '@openstore/react-ui'
import React from 'react'

export type IconTitleProps = {
  icon: React.ReactNode
  title?: React.ReactNode
  description?: React.ReactNode
  colorScheme?: 'error' | 'success' | 'warning' | 'gray' | 'primary'
  inline?: boolean
}

function IconTitle({ icon, title, description, colorScheme = 'gray', inline = false }: IconTitleProps) {
  const content = React.useMemo(() => {
    return (
      <Box mb={inline ? '2 !important' : undefined}>
        {title &&
          (typeof title === 'string' ? (
            <Text marginTop={inline ? undefined : '4'} textStyle="labelSemibold">
              {title}
            </Text>
          ) : (
            title
          ))}
        {description &&
          (typeof description === 'string' ? (
            <Text marginTop={inline ? undefined : '2'} textStyle="body1" color="gray.650">
              {description}
            </Text>
          ) : (
            description
          ))}
      </Box>
    )
  }, [title, description, inline])

  return (
    <>
      <HStack spacing="4">
        <HStack
          p="2"
          bg={colorScheme === 'primary' ? 'lavender.200' : `${colorScheme}.100`}
          borderRadius="36"
          width="fit-content"
          mt="-3"
          ml="-3"
        >
          <HStack p="2" bg={colorScheme === 'primary' ? 'lavender.200' : `${colorScheme}.200`} borderRadius="18">
            {icon}
          </HStack>
        </HStack>
        {inline && content}
      </HStack>

      {!inline && content}
    </>
  )
}

export default IconTitle
