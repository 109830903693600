import startCase from 'lodash/startCase'

type FormatStringOptions = {
  withLowerCaseConjuctions?: boolean
  withDefaultModifiers?: boolean
}
export function formatString(
  text: string,
  { withLowerCaseConjuctions = true, withDefaultModifiers = true }: FormatStringOptions = {}
) {
  let formattedString = startCase(text.toLowerCase())

  if (withLowerCaseConjuctions) {
    formattedString = formattedString
      .replaceAll(/ At /g, ' at ')
      .replaceAll(/ Of /g, ' of ')
      .replaceAll(/ And /g, ' and ')
      .replaceAll(/ For /g, ' for ')
      .replaceAll(/ To /g, ' to ')
      .replaceAll(/ In /g, ' in ')
      .replaceAll(/ With /g, ' with ')
      .replaceAll(/ By /g, ' by ')
  }

  if (withDefaultModifiers) {
    formattedString = formattedString.replaceAll('Sms', 'SMS')
  }

  return formattedString
}

export function isValidUuid(uuid: string) {
  return Boolean(uuid.match(/^[a-f\d]{8}(-[a-f\d]{4}){4}[a-f\d]{8}$/i))
}
