import { Box, Button, ModalProps as ChakraModalProps } from '@openstore/react-ui'
import React, { createContext } from 'react'

type ButtonProps = React.ComponentProps<typeof Button>

export type ModalProps = {
  title?: string | React.ReactNode
  async?: boolean
  size?: ChakraModalProps['size']
  primaryButtonProps?: Partial<ButtonProps> | false
  secondaryButtonProps?: Partial<ButtonProps> | false
  showFooter?: boolean
  showHeader?: boolean
  children?: React.ReactNode | ((renderProps: ModalRenderProps) => JSX.Element)
  pt?: React.ComponentProps<typeof Box>['pt']
  px?: React.ComponentProps<typeof Box>['px']
  background?: React.ComponentProps<typeof Box>['background']
  closeOnOverlayClick?: boolean
  scrollBehavior?: ChakraModalProps['scrollBehavior']
}

export type ModalRenderProps = {
  closeModal: ModalControllerProps['closeModal']
}

export type ModalControllerProps = {
  showModal: (ModalProps?: ModalProps) => void
  updateModal: (ModalProps?: ModalProps) => void
  closeModal: () => void
}

type ModalStateProps = {
  isOpen: boolean
  props: ModalProps | undefined
}

export const ModalControllerContext = createContext<ModalControllerProps>({
  showModal: () => undefined,
  updateModal: () => undefined,
  closeModal: () => undefined,
})

export const ModalStateContext = createContext<ModalStateProps>({
  isOpen: false,
  props: undefined,
})
