import { ErrorBlock } from '@components/ui'
import { Container } from '@openstore/react-ui'

import { Component } from 'react'
import { captureException } from 'libs/sentryClient'

export default class GlobalLayoutErrorBoundary extends Component<any, { error?: Error }> {
  constructor(props: any) {
    super(props)
    this.state = { error: undefined }
  }

  static getDerivedStateFromError(error: Error) {
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    captureException(error)
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.error) {
      return (
        <Container>
          <ErrorBlock description={this.state.error?.message} stack={this.state.error?.stack} />
        </Container>
      )
    }

    return this.props.children
  }
}
