import { Input } from '@openstore/react-ui'
import { InputProps } from '@openstore/react-ui/dist/components/input/Input'
import React from 'react'
import { DATE_FORMATS, formatToLocalDateTime, parseToTz, TimeZones } from 'utils/date'

export type DateTimePickerProps = {
  tz?: ValueOf<typeof TimeZones>
  onChange: (dateString: string) => void
} & Omit<InputProps, 'onChange'>

function DateTimePicker({ value, onChange, label = '', hint, errorMessage, tz = TimeZones.UTC }: DateTimePickerProps) {
  const localDateTime = formatToLocalDateTime(value, DATE_FORMATS.LOCAL_DATE_TIME, tz)
  return (
    <Input
      label={label}
      onChange={(event) => {
        const dateTimeString = event.target.value
        onChange(parseToTz(dateTimeString, tz))
      }}
      type="datetime-local"
      placeholder="Select date"
      value={localDateTime}
      errorMessage={errorMessage}
      hint={hint}
    />
  )
}

DateTimePicker.TimeZones = TimeZones

export default DateTimePicker
