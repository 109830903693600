import * as React from 'react'

import { Box, Flex, Grid, Stack, Text, theme } from '@openstore/react-ui'
import { DATE_FORMATS, formatDate } from 'utils/date'

type BoxProps = React.ComponentProps<typeof Box>

export type TimelineItemProps = {
  icon?: JSX.Element
  title: React.ReactNode
  timestamp?: string
  description?: React.ReactNode
  color?: string
  children?: React.ReactNode
  renderLeft?: React.ReactNode
  pt?: BoxProps['pt']
}

export type TimelineProps = {
  items: TimelineItemProps[]
  groupByDates?: boolean
}

function Title({ children, ...rest }: React.PropsWithChildren<React.ComponentProps<typeof Text>>) {
  return (
    <Text textStyle="body1Semibold" {...rest}>
      {children}
    </Text>
  )
}

function Description({ children, ...rest }: React.PropsWithChildren<React.ComponentProps<typeof Text>>) {
  return (
    <Text textStyle="body1" color="gray.650" {...rest}>
      {children}
    </Text>
  )
}

function Timestamp({ children, ...rest }: React.PropsWithChildren<React.ComponentProps<typeof Text>>) {
  return (
    <Text textStyle="body1" color="gray.650" {...rest}>
      {children}
    </Text>
  )
}

function Timeline({ items, groupByDates = false }: TimelineProps) {
  const itemsGroupedByDates = React.useMemo(
    () =>
      groupByDates
        ? items
            // Timestamp sorted in descending order
            .sort(
              ({ timestamp: timestampA = '' }, { timestamp: timestampB = '' }) =>
                new Date(timestampB).getTime() - new Date(timestampA).getTime()
            )
            .reduce((acc: { [key: string]: TimelineItemProps[] }, item) => {
              const timestamp = item.timestamp!

              // Convert the timestamp to local timezone
              const displayDate = formatDate(timestamp, DATE_FORMATS.DATE_TEXT)

              // If this date is not yet in the accumulator, add it with an empty array
              if (!acc[displayDate]) {
                acc[displayDate] = []
              }
              // Push the current object to the array for this date
              acc[displayDate].push({
                ...item,
                timestamp: formatDate(timestamp, 'h:mm a'),
              })
              return acc
            }, {})
        : {},
    [items, groupByDates]
  )

  return (
    <Stack spacing="6">
      {groupByDates ? (
        Object.keys(itemsGroupedByDates).map((date, index) => {
          return (
            <Box>
              <Text color="gray.650" marginBottom={6} textStyle="body1Semibold">
                {date}
              </Text>
              <Box>
                <TimelineCore key={index} items={itemsGroupedByDates[date]} />
              </Box>
            </Box>
          )
        })
      ) : (
        <TimelineCore items={items} />
      )}
    </Stack>
  )
}

function TimelineCore({ items }: TimelineProps) {
  const hasRenderLeft = React.useMemo(() => items.some((item) => Boolean(item.renderLeft)), [items])
  return (
    <Grid templateColumns={hasRenderLeft ? 'auto auto 1fr' : 'auto 1fr'} columnGap="4">
      {items.map((item, index: number) => {
        const isLast = index === items.length - 1
        const isFirst = index === 0
        return <TimelineItem {...item} key={index} isLast={isLast} isFirst={isFirst} />
      })}
    </Grid>
  )
}

const TimelineItem = ({
  renderLeft,
  isFirst,
  isLast,
  icon,
  title,
  timestamp,
  description,
  children,
  color,
  pt = '6px',
}: TimelineItemProps & {
  isFirst: boolean
  isLast: boolean
}) => {
  return (
    <>
      {renderLeft}

      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        _before={{
          content: `""`,
          display: 'block',
          width: '1px',
          height: '6px',
          borderLeft: `2px solid ${theme.colors.gray[300]}`,
          opacity: isFirst ? 0 : 1,
        }}
        _after={{
          content: `""`,
          display: 'block',
          width: '1px',
          flex: '1 1 auto',
          borderLeft: `2px solid  ${theme.colors.gray[300]}`,
          opacity: isLast ? 0 : 1,
        }}
      >
        {icon}
      </Flex>

      <Stack pt={pt} minH="50px">
        <Flex>
          {typeof title === 'string' ? (
            <Title flex={1} color={color}>
              {title}
            </Title>
          ) : (
            title
          )}
          <Timestamp>{timestamp}</Timestamp>
        </Flex>

        {description ? typeof description === 'string' ? <Description>{description}</Description> : description : null}

        {children}
      </Stack>
    </>
  )
}

Timeline.Title = Title
Timeline.Description = Description

export default Timeline
