import { SingleSelect } from '@openstore/react-ui'
import { OptionType } from '@openstore/react-ui/dist/common/react-select/types'
import CustomMenuList from './components/MenuList'
import { formatString } from 'utils/string'

export type Option = {
  value: string
  label: React.ReactNode
  renderRight?: OptionType['renderRight']
  showArrowIcon?: OptionType['showArrowIcon']
}

export function formatOption(option: Option | string | undefined, options: Option[]): Option | undefined {
  if (!option) return undefined

  if (typeof option === 'string') {
    return options.find((o) => o.value === option)
  }
  return option
}

export function parseOption(option: Option | undefined) {
  if (!option) return ''
  return option.value
}

export type SingleSelectProps = Omit<
  React.ComponentProps<typeof SingleSelect>,
  'onChange' | 'value' | 'defaultValue' | 'options'
> & {
  onChange: (newValue: string, newOption: Option) => void
  options: Option[]
  hint?: string
  value?: Option | string | undefined
  defaultValue?: Option | string | undefined
  isVirtualized?: boolean
  isCreatable?: boolean
}

export function Select({
  label,
  options,
  value,
  onChange,
  defaultValue,
  isVirtualized = true,
  isCreatable = false,
  isLoading,
  isDisabled,
  hint,
  ...rest
}: SingleSelectProps) {
  return (
    <SingleSelect
      label={label}
      hint={hint}
      defaultValue={formatOption(defaultValue, options)}
      value={formatOption(value, options)}
      onChange={(_newOption) => {
        const newOption = _newOption as Option
        onChange(parseOption(newOption), newOption)
      }}
      isCreatable={isCreatable}
      components={
        isVirtualized
          ? {
              MenuList: CustomMenuList,
            }
          : undefined
      }
      options={options}
      isLoading={isLoading}
      isDisabled={isLoading || isDisabled}
      {...rest}
    />
  )
}

Select.formatOptions = (obj: object) =>
  Object.values(obj).map((type) => ({
    label: formatString(type),
    value: type,
  }))

export default Select
