import { AlertStatus, Box, HStack, IconAlertCircle, IconAlertTriangle, Stack, Text } from '@openstore/react-ui'
import { IconProps } from '@openstore/react-ui/dist/components/icon/Icon'
import { TextProps } from '@openstore/react-ui/dist/components/text/Text'
import React from 'react'

type ThemeColor = TextProps['color']
type BannerStyles = {
  titleColor: ThemeColor
  descriptionColor: ThemeColor
  background: ThemeColor
  borderColor: ThemeColor
  icon: (props: IconProps) => JSX.Element
  iconColor: ThemeColor
}

export function getBannerStyles(type: AlertStatus = 'info'): BannerStyles {
  switch (type) {
    case 'error':
      return {
        titleColor: 'black',
        descriptionColor: 'gray.650',
        iconColor: 'error.500',
        borderColor: 'error.600',
        background: 'error.50',
        icon: IconAlertCircle,
      }
    case 'info':
      return {
        titleColor: 'black',
        descriptionColor: 'black',
        iconColor: 'black',
        background: 'white',
        borderColor: 'gray.300',
        icon: IconAlertCircle,
      }
    case 'warning':
      return {
        titleColor: 'black',
        descriptionColor: 'black',
        iconColor: 'warning.500',
        background: 'warning.25',
        borderColor: 'warning.500',
        icon: IconAlertTriangle,
      }
    case 'success': /** TODO: */
    case 'loading':
    default:
      return {
        titleColor: 'white',
        descriptionColor: 'white',
        iconColor: 'white',
        background: 'neutral.800',
        borderColor: 'neutral.800',
        icon: IconAlertCircle,
      }
  }
}

type Size = 'md' | 'lg'

export type BannerProps = {
  title: string | JSX.Element
  type?: AlertStatus
  description?: string | JSX.Element
  styles?: BannerStyles
  size?: Size
  borderRadius?: React.ComponentProps<typeof HStack>['borderRadius']
  renderRight?: React.ReactNode
}

function Banner({
  title,
  description,
  children,
  type = 'info',
  styles,
  size = 'lg',
  borderRadius = 'md',
  renderRight,
}: React.PropsWithChildren<BannerProps>) {
  const bannerStyles = styles ?? getBannerStyles(type)

  const { iconProps, titleProps, descriptionProps, containerProps } = React.useMemo(() => {
    switch (size) {
      case 'md': {
        return {
          containerProps: {
            p: '3',
          },
          iconProps: {
            width: '20px',
            height: '20px',
          },
          titleProps: {
            textStyle: 'body1Semibold',
          },
          descriptionProps: {
            textStyle: 'body1',
          },
        }
      }
      case 'lg':
      default: {
        return {
          containerProps: {
            p: '6',
          },
          iconProps: {
            width: '20px',
            height: '20px',
          },
          titleProps: {
            textStyle: 'labelSemibold',
          },
          descriptionProps: {
            textStyle: 'body1',
          },
        }
      }
    }
  }, [size])

  const Icon = bannerStyles.icon
  return (
    <HStack
      background={bannerStyles.background}
      border="1px"
      spacing="3"
      borderColor={bannerStyles.borderColor}
      borderRadius={borderRadius}
      {...containerProps}
    >
      <HStack spacing="4" alignItems={description || children ? 'start' : 'center'} flex={1}>
        <Icon color={bannerStyles.iconColor} mt={description ? '0.5' : undefined} {...iconProps} />
        <Stack spacing="0.5">
          {typeof title === 'string' ? (
            <Text color={bannerStyles.titleColor} {...titleProps}>
              {title}
            </Text>
          ) : (
            title
          )}
          {description ? (
            typeof description === 'string' ? (
              <Text color={bannerStyles.descriptionColor} {...descriptionProps}>
                {description}
              </Text>
            ) : (
              description
            )
          ) : null}
          <Box>{children}</Box>
        </Stack>
      </HStack>
      {renderRight}
    </HStack>
  )
}

export default Banner
