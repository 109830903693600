import {
  Box,
  Button,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Drawer as RawDrawer,
  Divider,
  Text,
  Stack,
} from '@openstore/react-ui'
import React, { useState } from 'react'
import { DrawerControllerContext, DrawerProps, DrawerStateContext } from './DrawerContext'
import IconTitle from '../Modal/components/IconTitle'

export const defaultPrimaryButtonProps = {
  children: 'Save',
  onClick: () => undefined,
}

export const defaultSecondaryButtonProps = {
  children: 'Cancel',
}

export function Drawer({
  title,
  primaryButtonProps = defaultPrimaryButtonProps,
  secondaryButtonProps = defaultSecondaryButtonProps,
  withDivider = false,
  showFooter = true,
  showHeader = true,
  showCloseButton = true,
  async,
  size = 'md',
  px = '8',
  pt = '0',
  children,
  isOpen,
  closeDrawer,
  closeOnOverlayClick = true,
}: DrawerProps & {
  isOpen: boolean
  closeDrawer: () => void
}) {
  const [loading, setLoading] = useState(false)
  const shouldPreventClose = !closeOnOverlayClick || (Boolean(async) && loading)

  const isOverlay = size === 'full'
  const hasFooter = showFooter && (primaryButtonProps || secondaryButtonProps)

  const handlePrimaryClick = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!primaryButtonProps || !primaryButtonProps.onClick) return

      if (async) {
        setLoading(true)
        primaryButtonProps.onClick && (await primaryButtonProps.onClick(event))
        setLoading(false)
        return
      }

      primaryButtonProps.onClick(event)
    },
    [primaryButtonProps, async]
  )

  return (
    <RawDrawer
      isOpen={isOpen}
      onClose={closeDrawer}
      closeOnOverlayClick={!shouldPreventClose}
      closeOnEsc={!shouldPreventClose}
      size={size}
      preserveScrollBarGap={true}
    >
      <DrawerOverlay />
      <DrawerContent margin="auto" overflow="hidden" data-height-full={true}>
        {showHeader && (
          <DrawerHeader
            mt="4"
            position="relative"
            lineHeight="30px"
            borderBottom={withDivider ? '1px' : undefined}
            borderColor={withDivider ? 'gray.300' : undefined}
          >
            <Box mr="5">{title}</Box>
            {showCloseButton && <DrawerCloseButton top="14px" right="6" size={isOverlay ? 'lg' : undefined} />}
          </DrawerHeader>
        )}

        <DrawerBody pb="0" pt={pt} px={px} display="flex" flexDir="column" flex={1}>
          {typeof children === 'function' ? children({ closeDrawer }) : children}
        </DrawerBody>

        {hasFooter && (
          <DrawerFooter mt="6" pt="6" pb="6" px={px} borderTop="1px" borderColor="gray.300">
            {secondaryButtonProps && (
              <Button
                variant="outline"
                onClick={closeDrawer}
                isDisabled={shouldPreventClose}
                {...defaultSecondaryButtonProps}
                {...secondaryButtonProps}
              />
            )}

            {primaryButtonProps && (
              <Button
                marginLeft={4}
                isLoading={loading}
                {...defaultPrimaryButtonProps}
                {...primaryButtonProps}
                onClick={handlePrimaryClick}
              />
            )}
          </DrawerFooter>
        )}
      </DrawerContent>
    </RawDrawer>
  )
}

Drawer.IconTitle = IconTitle
Drawer.Stack = function DrawerStack({ children }: React.PropsWithChildren) {
  return (
    <Stack flex={1} px="6" pb="6" overflow="auto" spacing="8" pointerEvents="auto" position="relative">
      {children}
    </Stack>
  )
}
Drawer.SectionTitle = function SectionTitle({ children }: React.PropsWithChildren) {
  return (
    <HStack>
      <Text color="gray.650" noOfLines={1}>
        {children}
      </Text>
      <Box flex={1}>
        <Divider color="gray.650" />
      </Box>
    </HStack>
  )
}

function DrawerManager() {
  const { isOpen, props } = React.useContext(DrawerStateContext)
  const { closeDrawer } = React.useContext(DrawerControllerContext)

  return <Drawer isOpen={isOpen} closeDrawer={closeDrawer} {...props} />
}

export default DrawerManager
