import { ToastId } from '@openstore/react-ui'
import { UseToastOptions } from '@openstore/react-ui/dist/proxy/components/toast'
import React from 'react'
import useToast from './useToast'

function Toast(props: UseToastOptions) {
  const toast = useToast()
  const toastId = React.useRef<ToastId>()

  React.useEffect(() => {
    toastId.current = toast(props)
    return () => {
      toastId.current && toast.close(toastId.current)
    }
  }, [])

  React.useEffect(() => {
    if (toastId.current) {
      toast.update(toastId.current, props)
    }
  }, [toast, props])

  return null
}

export default Toast
