import React from 'react'
import ExperimentServiceProvider from '@openstore/experiment/dist/client/components/ExperimentServiceProvider'

function ExperimentProvider({ children }: React.PropsWithChildren) {
  return (
    <ExperimentServiceProvider
      options={{
        sdkKey: process.env.NEXT_PUBLIC_STATSIG_API_KEY!,
        environment: (process.env.NODE_ENV === 'production' ? 'production' : 'staging') as any,
      }}
    >
      {children}
    </ExperimentServiceProvider>
  )
}

export default ExperimentProvider
