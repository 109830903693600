import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@openstore/react-ui'
import React, { useState } from 'react'
import { AlertControllerContext, AlertStateContext } from './AlertContext'
import IconTitle, { IconTitleProps } from '../Modal/components/IconTitle'

export const DEFAULTS = {
  TITLE: 'Are you sure?',
  DESCRIPTION: `You can't undo this action afterwards.`,
  PRIMARY_BUTTON_TEXT: 'Confirm',
  SECONDARY_BUTTON_TEXT: 'Cancel',
}

export const defaultPrimaryButtonProps = {
  children: DEFAULTS.PRIMARY_BUTTON_TEXT,
}

export const defaultSecondaryButtonProps = {
  children: DEFAULTS.SECONDARY_BUTTON_TEXT,
}

function AlertManager() {
  const { isOpen, props } = React.useContext(AlertStateContext)
  const { closeAlert } = React.useContext(AlertControllerContext)
  const [loading, setLoading] = useState(false)
  const cancelRef = React.useRef<HTMLButtonElement>(null)

  const {
    title = DEFAULTS.TITLE,
    description = DEFAULTS.DESCRIPTION,
    primaryButtonProps = defaultPrimaryButtonProps,
    secondaryButtonProps = defaultSecondaryButtonProps,
    onConfirm,
    async,
    size,
    showFooter = true,
  } = props ?? {}

  const shouldPreventClose = Boolean(async) && loading

  const handleConfirm = React.useCallback(async () => {
    if (!onConfirm) {
      return
    }
    if (async) {
      setLoading(true)
      await onConfirm()
      setLoading(false)
      return
    }

    onConfirm()
  }, [onConfirm, async])

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={closeAlert}
      closeOnEsc={!shouldPreventClose}
      closeOnOverlayClick={!shouldPreventClose}
      size={size}
    >
      <AlertDialogOverlay>
        <AlertDialogContent margin="auto">
          <AlertDialogHeader px="8" mt="4" position="relative" lineHeight="30px">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody pb="0" px="8" pt="0" color="gray.650" textStyle="body1">
            {description}
          </AlertDialogBody>

          {showFooter && (
            <AlertDialogFooter py="6" px="8" mt="4">
              {secondaryButtonProps && (
                <Button
                  ref={cancelRef}
                  variant="outline"
                  onClick={closeAlert}
                  isDisabled={shouldPreventClose}
                  {...defaultSecondaryButtonProps}
                  {...secondaryButtonProps}
                />
              )}

              {onConfirm && (
                <Button
                  ml={3}
                  isLoading={loading}
                  {...defaultPrimaryButtonProps}
                  {...primaryButtonProps}
                  onClick={handleConfirm}
                />
              )}
            </AlertDialogFooter>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

AlertManager.IconTitle = function AlertIconTitle(props: IconTitleProps) {
  return <IconTitle colorScheme="error" {...props} />
}

export default AlertManager
