import { Accordion, AccordionButton, AccordionItem, Text, AccordionIcon, AccordionPanel } from '@openstore/react-ui'
import { TextProps } from '@openstore/react-ui/dist/components/text/Text'
import React from 'react'

type CollapseProps = {
  title: React.ReactNode
  defaultExpanded?: boolean
  lazy?: boolean
  textStyle?: TextProps['textStyle']
  children: React.ReactNode | (() => JSX.Element)
  chevronPosition?: 'right' | 'left' | 'none'
  headerContent?: React.ReactNode
  isDisabled?: boolean
}
function Collapse({
  children,
  title,
  defaultExpanded,
  textStyle = 'headline4',
  lazy = false,
  chevronPosition = 'right',
  headerContent,
  isDisabled,
}: CollapseProps) {
  const [internalExpanded, setInternalExpanded] = React.useState(defaultExpanded ?? false)

  const showContent = lazy ? internalExpanded : true
  return (
    <Accordion
      defaultIndex={internalExpanded ? [0] : []}
      allowMultiple={true}
      overflow="hidden"
      onChange={() => {
        if (lazy) {
          setInternalExpanded(!internalExpanded)
        }
      }}
    >
      <AccordionItem overflow="hidden" isDisabled={isDisabled} border="none">
        <AccordionButton px="0" _hover={{ bg: 'inherit' }}>
          {chevronPosition === 'left' && <AccordionIcon width="24px" height="24px" mr="2" />}

          {typeof title === 'string' ? (
            <Text flex={1} textAlign="left" textStyle={textStyle}>
              {title}
            </Text>
          ) : (
            title
          )}

          {headerContent}
          {chevronPosition === 'right' && <AccordionIcon width="24px" height="24px" />}
        </AccordionButton>
        <AccordionPanel pb={4} px={0} overflow="hidden">
          {showContent ? (typeof children === 'function' ? children() : children) : null}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default Collapse
