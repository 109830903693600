import { Box, Flex, Spinner } from '@openstore/react-ui'
import NextImage, { ImageProps as NextImageProps } from 'next/image'
import React from 'react'

type BoxProps = React.ComponentProps<typeof Box>

export type ImageProps = NextImageProps &
  ImageFileProps & {
    background?: BoxProps['background']
    borderRadius?: BoxProps['borderRadius']
    border?: BoxProps['border']
    borderColor?: BoxProps['borderColor']
  }

export type ImageFileProps = {
  isNative?: boolean
  isUploading?: boolean
  containerProps?: BoxProps
}
export function ImageFile({ isUploading, isNative, containerProps, ...rest }: ImageFileProps & NextImageProps) {
  const src = rest.src as string
  const { width, height } = rest
  const fill = !width || !height

  if (isNative || fill) {
    return (
      <Flex
        opacity={isUploading ? 0.1 : undefined}
        position="relative"
        justify="center"
        align="center"
        width={width}
        height={height}
        {...containerProps}
      >
        <img src={src} alt={src} />
      </Flex>
    )
  }

  return (
    <Box opacity={isUploading ? 0.1 : undefined} {...containerProps}>
      <NextImage layout="fixed" objectFit="cover" {...rest} />
    </Box>
  )
}

function Image({
  isUploading,
  width,
  height,
  background = 'gray.100',
  borderRadius = '4',
  border = '1px',
  borderColor = 'gray.300',
  containerProps,
  ...rest
}: ImageProps) {
  return (
    <Box
      width={width}
      height={height}
      border={border}
      borderColor={borderColor}
      position="relative"
      borderRadius={borderRadius}
      overflow="hidden"
      bg={background}
      flexShrink={0}
      {...containerProps}
    >
      {rest.src && (
        <ImageFile
          width={width}
          height={height}
          isUploading={isUploading}
          containerProps={containerProps}
          {...rest}
          quality={rest.quality ?? 3}
        />
      )}
      {isUploading && (
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Spinner label="" size="sm" showLabel={false} />
        </Box>
      )}
    </Box>
  )
}

export default Image
