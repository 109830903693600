import MUIPagination from '@mui/material/Pagination'
import PaginationItem from '@mui/material/PaginationItem'
import { createTheme, ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import {
  VStack,
  HStack,
  Text,
  IconChevronLeft,
  IconChevronRight,
  EmotionChakraThemeProvider,
} from '@openstore/react-ui'
import React from 'react'
import { calculateNumberOfPages } from './utils'

function Previous() {
  return (
    <EmotionChakraThemeProvider>
      <HStack>
        <IconChevronLeft />
        <Text>Previous</Text>
      </HStack>
    </EmotionChakraThemeProvider>
  )
}

function Next() {
  return (
    <EmotionChakraThemeProvider>
      <HStack>
        <Text>Next</Text>
        <IconChevronRight />
      </HStack>
    </EmotionChakraThemeProvider>
  )
}

const MUItheme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial',
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          display: 'flex',
          width: '100%',
          ['.MuiButtonBase-root']: {
            marginRight: 0,
            marginLeft: 0,
            paddingRight: 0,
            paddingLeft: 0,
          },
          ['ul']: {
            display: 'flex',
            flex: 1,

            ['li:nth-of-type(1)']: {
              flex: 1,
            },
            ['li:last-child']: {
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            },
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        previousNext: {
          [':hover']: {
            background: 'inherit',
          },
        },
      },
    },
  },
})

export type PaginationProps = {
  size: number
  total?: number
  onChange: (newPage: number) => void
} & ({ page: number; defaultPage?: number } | { page?: never; defaultPage: number })

export default function Pagination({ size, total, page, defaultPage, onChange }: PaginationProps) {
  const numberOfPages = React.useMemo(() => calculateNumberOfPages(total, size), [total, size])

  return (
    <VStack mt="4">
      <MUIThemeProvider theme={MUItheme}>
        <MUIPagination
          count={numberOfPages}
          page={page}
          defaultPage={defaultPage}
          onChange={(_, newPage) => onChange(newPage)}
          renderItem={(item) => <PaginationItem components={{ previous: Previous, next: Next }} {...item} />}
        />
      </MUIThemeProvider>
    </VStack>
  )
}
