import { useSession } from 'next-auth/react'

export type User = {
  name: string
  email: string
  groups: string[]
}

type UserResponse = {
  loading: boolean
  user: User
}

function useUser(): UserResponse {
  /** if `{ required: true }` is supplied, `status` can only be "loading" or "authenticated" */
  const { data, status } = useSession({
    required: true,
  })

  if (status === 'loading') {
    return {
      loading: true,
      user: {
        name: '',
        email: '',
        groups: [],
      },
    }
  }

  return {
    loading: false,
    user: {
      name: data.user.name,
      email: data.user.email,
      groups: data.user.groups,
    },
  }
}

export default useUser
