import { Box, Flex, Text } from '@openstore/react-ui'
import Link from 'next/link'
import React from 'react'

type MenuItemProps = {
  href: string
  name: string
  active?: boolean
  children?: string
}

const MenuItem: React.FC<MenuItemProps> = ({ href, name, active = false }) => {
  return (
    <Link href={href}>
      <Flex
        cursor="pointer"
        align="center"
        overflow="hidden"
        minHeight="32px"
        borderBottom="2px"
        borderColor={active ? 'black' : 'white'}
      >
        <Box flex={1}>
          <Text textStyle={active ? 'body1Semibold' : 'body1'} color={active ? 'black' : 'gray.650'}>
            {name}
          </Text>
        </Box>
      </Flex>
    </Link>
  )
}

export default MenuItem
