import React from 'react'
import NextLink from 'next/link'
import { Box, Text, HStack, Link as OSLink, IconArrowUpRight } from '@openstore/react-ui'

type LinkProps = React.ComponentProps<typeof OSLink> & {
  withExternalIcon?: boolean
  textAlign?: React.ComponentProps<typeof Text>['textAlign']
  noOfLines?: React.ComponentProps<typeof Text>['noOfLines']
  color?: React.ComponentProps<typeof Text>['color']
  isDisabled?: boolean
}

export default function Link({
  children,
  textStyle = 'body1Semibold',
  color = 'primary.800',
  withExternalIcon,
  textAlign,
  noOfLines,
  isExternal,
  href,
  isDisabled,
  ...rest
}: LinkProps) {
  const Container = ({ children }: React.PropsWithChildren) => {
    if (isExternal || !href) {
      return (
        <OSLink
          textStyle={textStyle}
          isExternal={isExternal}
          href={href}
          sx={{
            cursor: isDisabled ? 'not-allowed' : 'pointer',
            pointerEvents: isDisabled ? 'none' : undefined,
            ...rest.sx,
          }}
          {...rest}
        >
          {children}
        </OSLink>
      )
    } else {
      return <NextLink href={href}>{children}</NextLink>
    }
  }

  return (
    <Box as="span" display="flex" cursor={isDisabled ? 'not-allowed' : 'pointer'} opacity={isDisabled ? 0.7 : 1}>
      <Container>
        <HStack
          display="inline-flex"
          spacing="1"
          _hover={{
            textDecoration: 'underline',
            textDecorationColor: color,
          }}
        >
          <Text color={color} textStyle={textStyle} textAlign={textAlign} noOfLines={noOfLines}>
            {children}
          </Text>
          {withExternalIcon && <IconArrowUpRight width="16px" height="16px" />}
        </HStack>
      </Container>
    </Box>
  )
}
