import React from 'react'
import { DrawerControllerContext, DrawerProps, DrawerStateContext } from './DrawerContext'

function DrawerProvider({ children }: React.PropsWithChildren) {
  const [isOpen, setIsOpen] = React.useState(false)
  const [props, setProps] = React.useState<DrawerProps | undefined>(undefined)

  const controls = React.useMemo(
    () => ({
      showDrawer: (props: DrawerProps | undefined) => {
        setProps(props)
        setIsOpen(true)
      },
      closeDrawer: () => {
        setIsOpen(false)
      },
      updateDrawer: (newProps: DrawerProps | undefined) => {
        setProps((props) => ({
          ...props,
          ...newProps,
        }))
      },
    }),
    []
  )

  return (
    <DrawerControllerContext.Provider value={controls}>
      <DrawerStateContext.Provider
        value={{
          isOpen,
          props,
        }}
      >
        {children}
      </DrawerStateContext.Provider>
    </DrawerControllerContext.Provider>
  )
}

export default DrawerProvider
