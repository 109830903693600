import React from 'react'
import { ChromePicker, ColorResult } from 'react-color'

type ColorPickerProps = {
  onChange: (color: string) => void
  defaultValue: string
}

function ColorPicker({ onChange, defaultValue }: ColorPickerProps) {
  const [internalValue, setInternalValue] = React.useState<React.CSSProperties['color']>(defaultValue)

  const handleChange = React.useCallback((result: ColorResult) => {
    setInternalValue(result.hex)
  }, [])

  const handleChangeComplete = React.useCallback(
    (result: ColorResult) => {
      handleChange(result)
      if (onChange) {
        onChange(result.hex)
      }
    },
    [handleChange, onChange]
  )

  return (
    <ChromePicker
      color={internalValue}
      onChange={handleChange}
      onChangeComplete={handleChangeComplete}
      disableAlpha={true}
      styles={{ default: { picker: { boxShadow: 'none' } } }}
    />
  )
}

export default ColorPicker
