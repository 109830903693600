import Header from '@components/Header'

import { Flex, Box } from '@openstore/react-ui'
import GlobalLayoutErrorBoundary from './GlobalLayoutErrorBoundary'
import { ReactElement } from 'react'
import React from 'react'
import { OSAppProps } from 'types/app'

function GlobalLayout({ pageProps, Component }: OSAppProps) {
  const getLayout = Component.getLayout ?? ((page: ReactElement) => page)
  const showHeader = Component.showHeader ?? true

  return (
    <Flex height="100%" flexDir="column" flex={1}>
      <Box id="os-ribbon-root" />
      {showHeader && <Header />}
      {/* Horizontal layout by default. Use `<Page />` to obtain the vertical layout. */}
      <Flex height="100%" flex={1} overflow="hidden">
        <GlobalLayoutErrorBoundary>{getLayout(<Component {...pageProps} />)}</GlobalLayoutErrorBoundary>
      </Flex>
    </Flex>
  )
}

export default GlobalLayout
