import { Flex, HStack, IconCheck, IconCircle, Text, theme } from '@openstore/react-ui'
import { IconProps } from '@openstore/react-ui/dist/components/icon/Icon'
import chroma from 'chroma-js'
import React from 'react'
import GroupSelectorContext, { GroupSelectorContextProps, useGroupContext } from './GroupSelectorContext'

const ACTIVE_BG_COLOR = chroma(theme.colors.primary['800']).alpha(0.05).hex()
const ERROR_BG_COLOR = chroma(theme.colors.error['500']).alpha(0.05).hex()

type ButtonSelectorProps = {
  value: string
  title: string
  description?: string
  icon?: (props: IconProps) => JSX.Element
  isDisabled?: boolean
}

function ButtonSelector({ value, icon, title, description, isDisabled }: ButtonSelectorProps) {
  const { value: currentValue, onChange, hasError } = useGroupContext()
  const isActive = value === currentValue

  return (
    <HStack
      as="button"
      type="button"
      onClick={() => onChange(value)}
      alignItems="center"
      border="1px"
      borderColor={hasError ? 'error.500' : isActive ? 'primary.800' : 'gray.300'}
      background={hasError ? ERROR_BG_COLOR : isActive ? ACTIVE_BG_COLOR : 'white'}
      borderRadius="md"
      p="4"
      spacing="3"
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      disabled={isDisabled}
      opacity={isDisabled ? 0.7 : 1}
    >
      {icon && (
        <Flex borderRadius="md" width="48px" height="48px" alignItems="center" justify="center">
          {React.createElement(icon, {
            width: '24px',
            height: '24px',
          })}
        </Flex>
      )}
      <HStack alignItems="start" flex={1}>
        <Flex flexDir="column" flex={1} alignItems="start">
          <Text textAlign="left" textStyle="body1Semibold">
            {title}
          </Text>
          {description && (
            <Text textAlign="left" color="gray.650" marginTop="0.5">
              {description}
            </Text>
          )}
        </Flex>
        <Flex>
          {isActive ? (
            <Flex width="16px" height="16px" borderRadius="8px" bg="primary.800" alignItems="center" justify="center">
              <IconCheck color="white" width="12px" height="12px" />
            </Flex>
          ) : (
            <IconCircle color="gray.300" />
          )}
        </Flex>
      </HStack>
    </HStack>
  )
}

type GroupSelectorProps = {
  onChange?: GroupSelectorContextProps['onChange']
  hasError?: boolean
} & (
  | { value: GroupSelectorContextProps['value']; defaultValue?: never }
  | { defaultValue: GroupSelectorContextProps['value']; value?: never }
)

function GroupSelector({
  children,
  value,
  defaultValue,
  onChange,
  hasError,
}: React.PropsWithChildren<GroupSelectorProps>) {
  const [internalValue, setInternalValue] = React.useState(defaultValue)
  const isUncontrolled = Boolean(defaultValue)
  const computedValue = isUncontrolled ? internalValue : value
  const handleOnChange = React.useCallback(
    (newValue: string) => {
      if (isUncontrolled) {
        setInternalValue(newValue)
      }

      if (onChange) {
        onChange(newValue)
      }
    },
    [isUncontrolled, onChange]
  )

  const contextValue = React.useMemo(() => {
    return {
      value: computedValue,
      onChange: handleOnChange,
      hasError,
    }
  }, [computedValue, hasError, handleOnChange])

  return <GroupSelectorContext.Provider value={contextValue}>{children}</GroupSelectorContext.Provider>
}

GroupSelector.Button = ButtonSelector

export default GroupSelector
