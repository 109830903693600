import { Box, HStack, IconTrash_01, Stack, Text } from '@openstore/react-ui'
import { ImageProps as NextImageProps } from 'next/image'
import React from 'react'
import Image, { ImageProps } from '../Image/Image'
import { ClientFileContent, ServerFileContent, isClientFile } from './utils'

type BoxProps = React.ComponentProps<typeof Box>

function ImageDisplay({
  borderColor = 'gray.300',
  border = '1px',
  fileContent,
  showFileName = true,
  renderLeft,
  handleDelete,
  backgroundColor = 'white',

  imageProps,
  isNative,
  isUploading,
  imageContainerProps,
}: {
  fileContent?: ServerFileContent | ClientFileContent
  renderLeft?: React.ReactNode
  showFileName?: boolean
  border?: BoxProps['border']
  borderColor?: BoxProps['borderColor']
  handleDelete?: () => void
  backgroundColor?: BoxProps['bg']

  isNative?: ImageProps['isNative']
  isUploading?: ImageProps['isUploading']
  imageContainerProps?: ImageProps['containerProps']
  imageProps?: Partial<NextImageProps>
}) {
  const shouldShowFileName = showFileName && fileContent && fileContent.fileName
  const showToolbar = (shouldShowFileName || handleDelete || renderLeft) && !isUploading
  return (
    <Box backgroundColor={backgroundColor} border={border} borderRadius="md" borderColor={borderColor} p="4">
      <Stack spacing="4">
        {showToolbar && (
          <HStack>
            {renderLeft}
            {shouldShowFileName && fileContent && (
              <Text flex={1} textStyle="body2Semibold" noOfLines={1}>
                {fileContent.fileName}
              </Text>
            )}
            {handleDelete && (
              <Box as="button" type="button" onClick={handleDelete}>
                <IconTrash_01 color="gray.650" />
              </Box>
            )}
          </HStack>
        )}

        <Image
          isNative={isNative}
          isUploading={isUploading}
          containerProps={imageContainerProps}
          border="none"
          background="white"
          {...imageProps}
          src={fileContent ? (isClientFile(fileContent) ? fileContent.fileData : fileContent.fileUrl) : ''}
        />
      </Stack>
    </Box>
  )
}

export default ImageDisplay
