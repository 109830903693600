import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ClickToComponent } from 'click-to-react-component'
import { GlobalLayout } from 'components/GlobalLayout'
import { SessionProvider } from 'next-auth/react'

import Head from 'next/head'
import './_app.scss'

import NextNProgress from 'nextjs-progressbar'

import { AlertManager, AlertProvider } from '@components/ui/Alert'
import { DrawerManager, DrawerProvider } from '@components/ui/Drawer'
import { ModalManager, ModalProvider } from '@components/ui/Modal'
import '@fontsource/inter/400.css'
import '@fontsource/inter/600.css'
import '@fontsource/sora/600.css'
import { ThemeProvider, ToastProvider, theme } from '@openstore/react-ui'
import ExperimentProvider from 'libs/experiment/ExperimentProvider'
import { OSAppProps } from 'types/app'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60,
      retry: false,
    },
  },
})

export default function OpsApp(props: OSAppProps) {
  return (
    <>
      <Head>
        <title>Ops Central</title>
        <meta name="robots" content="noindex" />
      </Head>

      <NextNProgress color={theme.colors.primary[600]} showOnShallow={false} options={{ showSpinner: false }} />

      <ExperimentProvider>
        <ThemeProvider>
          <SessionProvider>
            <QueryClientProvider client={queryClient}>
              <ToastProvider>
                <DrawerProvider>
                  <ModalProvider>
                    <AlertProvider>
                      <GlobalLayout {...props} />
                      <ClickToComponent />
                      <AlertManager />
                      <ModalManager />
                      <DrawerManager />
                    </AlertProvider>
                  </ModalProvider>
                </DrawerProvider>
              </ToastProvider>

              <ReactQueryDevtools initialIsOpen={false} panelPosition="right" position="top-right" />
            </QueryClientProvider>
          </SessionProvider>
        </ThemeProvider>
      </ExperimentProvider>
    </>
  )
}
