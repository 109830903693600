import { Box, SingleSelect } from '@openstore/react-ui'
import React from 'react'
import VirtualList, { ItemInfo } from 'react-tiny-virtual-list'

const DefaultItemHeight = 35

type SingleSelectProps = React.ComponentProps<typeof SingleSelect>
type ListProps = React.ComponentProps<
  NonNullable<Pick<NonNullable<SingleSelectProps['components']>, 'MenuList'>['MenuList']>
>

function CustomMenuList({ options, children, maxHeight, getValue }: ListProps) {
  const renderItem = (props: ItemInfo) => {
    if (Array.isArray(children)) {
      return (
        <Box as="li" style={props.style} key={props.index} listStyleType="none">
          {children[props.index]}
        </Box>
      )
    }

    return (
      <Box as="li" key={props.index} listStyleType="none">
        {children}
      </Box>
    )
  }

  const [value] = getValue()
  const initialOffset = options.indexOf(value) * DefaultItemHeight
  const childrenOptions = React.Children.toArray(children)
  const wrapperHeight =
    maxHeight < childrenOptions.length * DefaultItemHeight ? maxHeight : childrenOptions.length * DefaultItemHeight

  return (
    <VirtualList
      height={wrapperHeight + 6}
      scrollOffset={initialOffset}
      itemCount={childrenOptions.length}
      itemSize={DefaultItemHeight}
      renderItem={renderItem}
    />
  )
}

export default CustomMenuList
