import { useToast } from '@components/ui'
import { Box, Dropzone, HStack, IconTrash_01, Stack, Text } from '@openstore/react-ui'
import React from 'react'
import ImageDisplay from './ImageDisplay'
import { ClientFileContent, FileContent, SupportedType, getFileProperties } from './utils'
function noOp() {}

function convertErrorMessage(message: string) {
  const regex = /(\d+)\sbytes/
  const match = message.match(regex)

  if (match) {
    const bytes = parseInt(match[1])
    const megabytes = (bytes / 1048576).toFixed(0)
    return message.replace(regex, `${megabytes}MB`)
  }

  return message
}

export type FileUploadProps = {
  type?: SupportedType
  onChange: (fileContent: ClientFileContent | undefined, file?: File) => void
  defaultValue?: FileContent
  value?: FileContent | null
  border?: React.ComponentProps<typeof Box>['border']
  borderColor?: React.ComponentProps<typeof Box>['borderColor']
  isDisabled?: boolean
  isNative?: boolean
  isUploading?: boolean
  multiple?: boolean
  onUpload?: React.ComponentProps<typeof Dropzone>['onUpload']
  maxSize?: number
  onPaste?: (fileContent: ClientFileContent | undefined, file?: File) => void
  maxPixels?: number
}

const isImageDimensionValid = (fileProperties: ClientFileContent, toast: any, maxPixels: number | undefined) => {
  if (maxPixels === undefined) {
    return true
  }

  const width = fileProperties.width ?? 1
  const height = fileProperties.height ?? 1
  const pixels = width * height

  if (pixels > maxPixels) {
    toast({
      title: `The pixel limit is ${maxPixels}. Resize your image and upload it again.`,
      description: fileProperties.fileName,
      status: 'error',
      isClosable: true,
    })
    return false
  }

  return true
}

/**
 * Wrapper file upload. ONlY supports a single file as of now.
 */
function FileUpload({
  value,
  defaultValue,
  type = SupportedType.Image,
  onChange,
  isDisabled = false,
  borderColor = 'gray.300',
  border = '1px',
  isNative = false,
  isUploading = false,
  onUpload = noOp,
  multiple = false,
  onPaste,
  maxSize = 20 * 1024 * 1024, // 20 MB
  maxPixels,
}: FileUploadProps) {
  const toast = useToast()
  /** URL or base64 string */
  const [_fileContent, setFileContent] = React.useState<FileContent | undefined>(defaultValue)

  const isUncontrolled = value === undefined
  const fileContent = isUncontrolled ? _fileContent : value

  // const handlePaste = React.useCallback(
  //   async (event: ClipboardEvent) => {
  //     if (!onPaste) {
  //       return
  //     }
  //     const items = event.clipboardData?.items || []

  //     for (let i = 0; i < items.length; i++) {
  //       if (items[i].type.indexOf('image') !== -1) {
  //         const blob = items[i].getAsFile()
  //         const fileName = 'pasted_image_' + new Date().getTime()
  //         const file = new File([blob!], fileName, { type: items[i].type })
  //         const fileProperties = await getFileProperties(file)

  //         if (fileProperties.fileSize > maxSize) {
  //           toast({
  //             title: 'Failed to upload file. Image size exceeds 20MB limit.',
  //             status: 'error',
  //             isClosable: true,
  //           })
  //           break
  //         }

  //         await onPaste(fileProperties)
  //         event.preventDefault()
  //       }
  //     }
  //   },
  //   [maxSize, onPaste, toast]
  // )

  /**
   * Turning off paste until we can measure the number of pixels
   */
  // useEffect(() => {
  //   window.addEventListener('paste', handlePaste)

  //   return () => {
  //     window.removeEventListener('paste', handlePaste)
  //   }
  // }, [handlePaste])

  const { description, accept } = React.useMemo(() => {
    switch (type) {
      case SupportedType.CSV:
        return {
          description: 'Accepts .CSV files',
          accept: {
            'text/plain': ['.csv'],
          } as Record<string, string[]>,
        }
      case SupportedType.ImageHeroBanner:
        return {
          description: 'WEBP or JPG',
          accept: {
            /** Be selective on image types for site speed */
            'image/webp': ['.wepb'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
          } as Record<string, string[]>,
        }
      case SupportedType.Image:
      default:
        return {
          description: 'WEBP, SVG, JPG, PNG or GIF',
          accept: {
            /** Be selective on image types for site speed */
            'image/webp': ['.wepb'],
            'image/jpg': ['.jpg'],
            'image/jpeg': ['.jpeg'],
            'image/svg': ['.svg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
          } as Record<string, string[]>,
        }
    }
  }, [type])

  const handleDrop = React.useCallback(
    async (files: File[]) => {
      files.forEach(async (file) => {
        switch (type) {
          case SupportedType.CSV: {
            const fileProperties = await getFileProperties(file)
            if (isUncontrolled) {
              setFileContent(fileProperties)
            }
            onChange(fileProperties, file)
            break
          }
          case SupportedType.Image:
          default: {
            const fileProperties = await getFileProperties(file)

            const img = new Image()
            img.src = fileProperties.fileData
            await img.decode()

            fileProperties.width = img.naturalWidth
            fileProperties.height = img.naturalHeight
            fileProperties.aspectRatio = img.naturalWidth / img.naturalHeight

            if (!isImageDimensionValid(fileProperties, toast, maxPixels)) {
              return
            }

            if (isUncontrolled) {
              setFileContent(fileProperties)
            }
            onChange(fileProperties, file)
          }
        }
      })
    },
    [isUncontrolled, onChange, type, maxPixels]
  )

  const handleDelete = React.useCallback(() => {
    if (isUncontrolled) {
      setFileContent(undefined)
    }
    onChange(undefined, undefined)
  }, [isUncontrolled, onChange])

  if (fileContent) {
    // TODO: Pretty bad design. Need to extract these into separate invokable components.
    switch (type) {
      case SupportedType.CSV:
        return (
          <Stack border={border} borderRadius="md" borderColor={borderColor} p="4" spacing="4" bg="white">
            <HStack>
              {fileContent.fileName && (
                <Box flex={1}>
                  <Text textStyle="body1Semibold">{fileContent.fileName}</Text>
                </Box>
              )}
              <Box as="button" type="button" onClick={handleDelete}>
                <IconTrash_01 color="gray.650" />
              </Box>
            </HStack>
          </Stack>
        )
      case SupportedType.ImageHeroBanner:
      case SupportedType.Image:
      default: {
        return (
          <ImageDisplay
            border={border}
            borderColor={borderColor}
            fileContent={fileContent}
            handleDelete={handleDelete}
            isNative={isNative}
            isUploading={isUploading}
          />
        )
      }
    }
  }

  return (
    <Dropzone
      description={onPaste ? `Paste image to upload. ${description}` : description}
      isDisabled={isDisabled}
      accept={accept}
      multiple={multiple}
      onUpload={onUpload}
      onDropAccepted={handleDrop}
      maxSize={maxSize}
      onDropRejected={(rejections) => {
        const rejectionReasons = rejections
          .map((rejection) => rejection.errors.map((error) => error.message).join(', '))
          .join('. ')
        toast({
          title: `Failed to upload file. ${convertErrorMessage(rejectionReasons)}.`,
          status: 'error',
          isClosable: true,
        })
      }}
    />
  )
}

FileUpload.Type = SupportedType

export default FileUpload
