import { MenuItem } from '@components/ui'
import { Box, Flex, HStack, OpenStoreLogo as OpenStoreLogoRaw } from '@openstore/react-ui'
import useUser from 'hooks/useUser'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import ROUTES from 'routes'
import { isOMCUser } from 'utils/auth'

const OpenStoreLogo = React.forwardRef(() => {
  return <OpenStoreLogoRaw width="100%" height="28px" />
})
OpenStoreLogo.displayName = 'OpenStoreLogo'

export default function Header() {
  const { asPath } = useRouter()
  const { user, loading } = useUser()

  const links = React.useMemo(() => {
    if (loading) return []

    return isOMCUser(user.groups)
      ? [
          {
            name: 'Commerce',
            href: ROUTES.COMMERCE.route(),
          },
          {
            name: 'Gumdrop',
            href: ROUTES.GUMDROP.route(),
          },
        ]
      : [
          {
            name: 'Storefronts',
            href: ROUTES.STOREFRONTS.route(),
          },
          {
            name: 'Marketing',
            href: ROUTES.MARKETING.route(),
          },
          {
            name: 'Commerce',
            href: ROUTES.COMMERCE.route(),
          },
          {
            name: 'Merchant',
            href: ROUTES.MERCHANT.route(),
          },
          {
            name: 'Gumdrop',
            href: ROUTES.GUMDROP.route(),
          },
          {
            name: 'SEO',
            href: ROUTES.SEO.route(),
          },
        ]
  }, [loading, user.groups])

  return (
    <Flex as="header" px="5" py="3" borderBottom="1px" borderColor="gray.200" alignItems="center" background="white">
      <Box cursor="pointer" flexShrink="0" width="80px">
        <Link href={ROUTES.BASE.route()}>
          <OpenStoreLogo />
        </Link>
      </Box>

      <HStack spacing="4" align="center" flex={1} justifyContent="center">
        {links.map((link) => {
          const active = asPath.startsWith(link.href)
          return <MenuItem key={link.name} active={active} {...link} />
        })}
      </HStack>

      <Box width="80px" />
    </Flex>
  )
}
