import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://deb28b9b95d041dbbb4c4ca98171c296@o917808.ingest.sentry.io/4503927454695424',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
})

export function captureException(err: Error | unknown) {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  Sentry.captureException(err)
}
