import { useRouter } from 'next/router'
import React from 'react'
import { Tabs } from '@components/ui'

export type TabProps = {
  name: string
  href: string
}

function PageHeaderTabs({ items = [] }: { items: TabProps[] }) {
  const router = useRouter()
  const defaultIndex = React.useMemo(() => {
    const foundIndex = items.findIndex((item) => router.asPath.startsWith(item.href))
    return foundIndex > -1 ? foundIndex : 0
  }, [items, router])

  return <Tabs index={defaultIndex} items={items} />
}

export default PageHeaderTabs
